@import '../../../styles/vars';
@import '../../../styles/mixins';

.card {
  position: relative;
  width: 100%;
  height: 100%;
  clip-path: polygon(100% 0%, 100% 100%, 16px 100%, 0 calc(100% - 16px), 0 0);
  transition: transform 0.4s;
  padding-bottom: 140px;
  cursor: pointer;
  backface-visibility: hidden;
  will-change: transform;

  &:hover {
    transform: translateY(-40px);
  }

  &.type_completed {
    .points {
      color: $danger;
    }
  }

  &.type_incompleted {
    &:hover {
      .points {
        color: $danger;
      }
    }
  }
}

.cover {
  @include aspect-ratio(310, 244);
}

.image {
  @include position(absolute, 0);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: $danger;
  background-blend-mode: multiply;
  transition: filter .3s;
}
.body {
  // @include aspect-ratio(55, 31);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 140px;
  background-color: $black;
  transition: height .32s;
  overflow: hidden;
  will-change: transform;
  backface-visibility: hidden;
}

.container {
  @include position(absolute, 9px);
  backface-visibility: hidden;
}

.wrapper {
  @include position(absolute, 0);
  background: $white;
  clip-path: polygon(100% 0%, 100% 100%, 10px 100%, 0 calc(100% - 10px), 0 0);
}

.content {
  @include position(absolute, 1px);
  display: flex;
  clip-path: polygon(100% 0%, 100% 100%, 10px 100%, 0 calc(100% - 10px), 0 0);
  background: #000;
}

.box {
  display: flex;
  flex-direction: column;
  width: 40%;
  &:first-child {
    width: 60%;
    padding: 0;
    border-right: 1px solid $white;

  }

  &:last-child {
    justify-content: space-between;

    .title {
      margin-bottom: 6px;
    }
  }
}

.text {
  width: 100%;
  height: 100%;
  
}

.title {
  font-family: 'GothamPro';
  font-size: 20px;
  font-weight: 700;
  line-height: 22px;
  text-transform: uppercase;
  letter-spacing: 3.14286px;
  color: $white;
  box-sizing: border-box;
  padding: 12px 13px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background: #000;
  height: 46px;
}

.description {
  font-family: 'HPSimplified';
  line-height: 14px;
  color: $white;
  top: 46px;
  height:74px;
  display: flex;
  align-items: flex-end;
  padding: 5px 12px 20px 12px;
  font-size: 12px;
  line-height: 1.36;
  user-select: none;
  pointer-events: none;
  
}

.points {
  font-family: 'HPSimplified';
  font-size: 46px;
  padding: 0px 12px;

  color: $danger;
  font-weight: bold;

  @include media-breakpoint-up(xl) {
  }
}

.checkbox {
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 1;
}

.button {
  transition: transform .3s;
  height: 52px;
  display: inline-block;
  line-height: 51px;
  border-top: 1px solid $white;
  font-family: 'GothamPro';
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 2.2px;
  color: $white;
  transform: translateY(40px);
  text-align: center;

  &::before {
    content: '';
    z-index: -1;
    opacity: 1;
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    background: $danger;
    transform: scale(0, 1);
    transform-origin: left;
    transition: transform 0.5s, opacity 1s;
    transition-timing-function: cubic-bezier(0.910, 0.060, 1.000, 1.000);
  }

  &.buttonFull::before {
    transform: scale(1, 1);
  }

  &.semiFilled::before {
    opacity: 1;
    transform: scale(.45, 1);
    transform-origin: left;
  }

  &:hover::before {
    opacity: 1;
    transform: scale(1, 1);
    transition: transform 0.5s, opacity .7s;
    transform-origin: left;
  }
}


.card:hover{ 
  .button {
    transform: translateY(0)
  }

  .body {
    height: 192px;
  }

  .image {
    filter: grayscale(100%)
  }
}

:global(.isMobile) {
  .card {
    &:hover {
      transform: scale(1);
    }
  }
}
