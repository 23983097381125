@import '../../styles/vars';

.tabs {
  display: flex;
}

.item {
  position: relative;
  display: inline-flex;
  flex: 1 0 auto;
  padding: 8px 10px;
  border-top: 1px solid $danger;
  border-bottom: 1px solid $danger;
  border-left: 1px solid $danger;
  font-family: 'GothamPro';
  font-weight: 700;
  font-size: 14px;
  line-height: 28px;
  letter-spacing: 1.6px;
  text-transform: uppercase;
  color: $white;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.1);

  &:last-child {
    border-right: 1px solid $danger;
  }
}

.active {
  background-color: $danger;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: -17px;
    width: 100%;
    height: 16px;
    background-image: url('./stripe.svg');
  }
}
