@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}

@mixin fluid($min-vw, $max-vw, $min-value-size, $max-value-size, $property: font-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-value-size);
  $u4: unit($max-value-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {

      #{$property}: $min-value-size;
      @media screen and (min-width: $min-vw) {
        #{$property}: calc(#{$min-value-size} + #{strip-unit($max-value-size - $min-value-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
      }

      @media screen and (min-width: $max-vw) {
        #{$property}: $max-value-size;
      }
    }
  }
}
