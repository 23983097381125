@import '../../styles/vars';
@import '../../styles/mixins';

.root {
  padding-top: $headerHeight;
  background-color: $black;
  min-height: calc(100vh - 101px);
}

.container {
  max-width: 1920px;
  margin: 0 auto;
  @include fluid($breakpoint-xl, $breakpoint-4xl, 30px, 74px, padding-top);
  @include fluid($breakpoint-xl, $breakpoint-4xl, 30px, 66px, padding-left);
  @include fluid($breakpoint-xl, $breakpoint-4xl, 30px, 66px, padding-right);
  @include fluid($breakpoint-xl, $breakpoint-4xl, 30px, 74px, padding-bottom);
}

.header {
  display: flex;
  justify-content: space-between;
  @include fluid($breakpoint-xl, $breakpoint-4xl, 40px, 90px, margin-bottom);
}

.player {
  display: flex;
}

.avatar {
  @include fluid($breakpoint-xl, $breakpoint-4xl, 194px, 310px, width);
  @include fluid($breakpoint-xl, $breakpoint-4xl, 177px, 285px, height);
  position: relative;
  clip-path: polygon(100% 0, 100% 93%, 94% 100%, 0 100%, 0 0);
  background-repeat: no-repeat;
  background-size: cover;
  background-color: $danger;
  background-blend-mode: multiply;
}

.info {
  @include fluid($breakpoint-xl, $breakpoint-4xl, 20px, 40px, margin-left);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.label {
  font-family: 'HPSimplified';
  font-weight: 400;
  @include fluid($breakpoint-xl, $breakpoint-4xl, 12px, 16px, font-size);
  @include fluid($breakpoint-xl, $breakpoint-4xl, 18px, 23px, line-height);
  color: $white;
}

.title {
  font-family: 'GothamPro';
  font-weight: 700;
  @include fluid($breakpoint-xl, $breakpoint-4xl, 23px, 40px, font-size);
  @include fluid($breakpoint-xl, $breakpoint-4xl, 32px, 50px, line-height);
  color: $white;
}

.cards {
  display: flex;
}

.item {
  @include fluid($breakpoint-xl, $breakpoint-4xl, 217px, 399px, width);

  &:not(:first-child) {
    @include fluid($breakpoint-xl, $breakpoint-4xl, 32px, 64px, margin-left);
  }
}

.emptyText {
  font-family: 'GothamPro';
  font-weight: 700;
  font-size: 20px;
  color: $white;
}
