@import '../../styles/_vars';

.root {
  color: #fff;
  height: 100%;
  overflow: hidden;
}

.header {
  position: relative;
  padding-top: 50px;
  box-sizing: border-box;
  width: 100%;
  box-shadow: 0 10px 10px 5px #000;
  z-index: 1;
}

.title {
  font-size: 55px;
  font-family: 'GothamPro';
  font-weight: bold;
  padding-left: 53px;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 45px;
    height: .76em;
    top: 50%;
    margin-top: -.38em;
    background-image: linear-gradient(45deg,
      #000000 45.45%,
      $danger 45.45%,
      $danger 50%,
      #000000 50%,
      #000000 95.45%,
      $danger 95.45%,
      $danger 100%
    );
    background-size: 15.56px 15.56px;
  }

  @media screen and (max-width: 680px) {
    font-size: 32px;
  }
}


.cardsList {
  margin-left: 13px;
}

.replayLabel {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 13px;
  color: #6D7278;
  text-transform: none;
  vertical-align: middle;
  margin-right: 14px;
  white-space: nowrap;
}

.replayFooter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.replayInput {
  width: 100%;
  background-color: transparent;
  height: 26px;
  line-height: 26px;
  padding: 0 10px;
  outline: none;
  border: 0;
  border-bottom: 1px solid #404040;
  font-family: inherit;
  font-size: 14px;
  transition: border .3s;
  color: #fff;
  color: #6D7278;

  &:focus {
    border-bottom-color: lighten(#404040, 20%);
  }
}

.inner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  overflow: hidden;
}
.body {
  overflow: auto;
  height: 100%;
  flex-grow: 1;
  flex-shrink: 1;
  width: 100%;
  padding-top: 1px;
  padding-bottom: 1px;
}

.spacer {
  height: 40px;
}


.footer {
  flex-grow: 0;
  height: 40px;
  background-color: #c0c0c0;
  width: 100%;
  position: relative;
  box-shadow: 0 -10px 10px 5px #000;
  background-color: #000;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  border: 1px solid #fff;
}

.formWrapper {
  margin-left: 53px;
  margin-top: 30px;
  max-width: 447px;
}
.approveTitle {
  color: $danger;
  font-weight: bold;
  font-weight: bold;
  font-size: 22px;
  line-height: 30px;
  font-family: 'GothamPro';
  margin-bottom: 1em;
}

.formTitle {
  font-family: 'GothamPro';
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 2;
  margin-bottom: .55em;
}

.formRow {
  margin-bottom: 20px;
}
.formLabel {
  margin-bottom: 10px;
  display: block;
  font-family: 'GothamPro';
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 11px;
  color: #6D7278;

  span {
    color: $danger
  }
}

.formText {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;
  color: #636363;

  a {
    color: $danger
  }
}

.form {
  margin-bottom: 40px;
}

.total {
  flex: 0 0 50%;
  font-family: 'GothamPro';
  background-color: #000;
}

.totalLabel {
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 1.8px;
  text-transform: uppercase;
}

.totalInner {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #000;
  padding: 0 20px;
  position: relative;
  transform: translateZ(0);
}

.totalSumm {
  color: $danger;
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 2.7px;
  text-align: right;

  span {
    letter-spacing: 0;
    text-transform: uppercase;
    color: #fff;
    font-size: .6em;
  }
}
.cartBtnWrapper {
  flex: 0 0 50%;
  max-width: 50%;
  background-color: #000;
  position: relative;
}
.cartBtn {
  width: 100%;
  display: block;
  background-color: $danger;
  border: 0;
  text-align: left;
  color: #fff;
  outline: none;
  height: 40px;
  line-height: 40px;
  font-size: 12px;
  line-height: 27px;
  letter-spacing: 1.8px;
  text-transform: uppercase;
  font-weight: bold;
  font-family: 'GothamPro';
  padding-left: 20px;
  cursor: pointer;
  position: relative;
  transition: background .3s;
  border-left: 1px solid #fff;

  &:active {
    transform: translateY(1px);
    background: darken($danger, 20%);
  }

  span {
    position: absolute;
    top: 50%;
    display: block;
    width: 12%;
    height: 2px;
    background-color: #fff;
    margin-top: -1px;
    right: 19px;

    &::after {
      content: "";
      width: 10px;
      height: 10px;
      display: block;
      position: absolute;
      transform-origin: right center;
      border: 2px solid #fff;
      transform: translateY(-.5px) rotateZ(45deg);
      right: 1px;
      border-bottom: 0;
      border-left: 0;
    }
  }

  &:hover {
    background-color: darken($danger, 10%);
  }
}

.account {
  position: absolute;
  top: 68px;
  right: 40px;
  z-index: 3;
  font-family: 'GothamPro';

}

.accountLabel {
  font-family: 'GothamPro';
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  line-height: 11px;
  letter-spacing: 1.7325px;
  text-transform: uppercase;
  color: #636363;
  margin-bottom: 4px;
}

.accountSumm {
  font-family: 'GothamPro';
  font-style: normal;
  font-weight: bold;
  font-size: 22.5px;
  line-height: 22px;
  color: #FFFFFF;

  span {
    color: $danger;
    font-size: 0.6em;
    font-weight: bold;
  }
}

.cartBtnWrapper,
.total {
  position: relative;
}

.cartBtnWrapper::before,
.total::before {
  position: absolute;
  content: "";
  display: block;
  height: 10px;
  width: 100%;
  top: 0px;
  left: 0;
  background-image: linear-gradient(45deg, #ffffff 7.14%, #000000 7.14%, #000000 50%, #ffffff 50%, #ffffff 57.14%, #000000 57.14%, #000000 100%);
  background-size: 9.90px 9.90px;
  opacity: 0;
  transition: 
    opacity .3s;
}

.cartBtnWrapper:hover::before,
.total:hover::before {
  transform: translateY(-11px);
  opacity: 1;
}

.trackVertical {
  background-color: lighten(#000, 10%);
  right: 0;
  height: 100%;

  div {
    background-color: $danger !important;
    border-radius: 3px !important;
  }
}

.footerWarn {
  color: #fff;
  background: #000;
  outline: none;
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  display: inline-block;
  width: 100%;
  text-align: right;
  padding-right: 20px;
  color: $danger;
  border-left: 1px solid #fff;
  font-weight: bold;
  position: relative;
}