@import '../../styles/vars';
@import '../../styles/mixins';

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  color: $white;
  font-family: 'HPSimplified';
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  margin-bottom: 16px;

  &:hover {
    color: $danger;
  }
}

.active {
  color: $danger;
}

.nickname {
  width: 18.2%;

  @include text-overflow();
}

.steamlink {
  color: #fff;
  text-decoration: none;
  transition: color .3s;

  &:hover {
    color: #ea002a;
  }
}

.line {
  width: 6.8%;
  height: 1px;
  background-color: rgba(74, 74, 74, 0.4);
}

.rank {
  width: 4%;
  text-align: right;
}

.rankStatus {
  width: 1.4%;
  line-height: 0;
}

.icon {
  width: 13px;
  height: 13px;
  transform-origin: 8px;

  &_up {
    stroke: $danger;
    transform: rotate(-90deg);
  }

  &_down {
    transform: rotate(90deg);
    stroke: #4a4a4a;
  }

  &_unchanged {
    display: none;
  }
}

:global(.isMobile) {
  .item {
    border-bottom: 1px solid rgba(74, 74, 74, 0.4);

    & > div:nth-child(2) {
      display: none;
      margin-bottom: -28px;

      @include media-breakpoint-up(l) {
        display: block;
      }
    }
  }

  .nickname {
    width: 40%;

    @include media-breakpoint-up(l) {
      width: 18.2%;
    }
  }

  .line {
    height: 0;
  }
}
