@import '../../../styles/vars';
@import '../../../styles/mixins';

.card {
  @include position(absolute, 0);

  text-decoration: none;
  cursor: pointer;
}

.wrapper {
  @include position(absolute, 0 1px 0 1px);
}

.frame {
  position: absolute;
  z-index: 1;
  stroke: $white;
}

.cover {
  @include aspect-ratio(6, 7);
  overflow: hidden;
}

.image {
  @include position(absolute, 16px);

  background-image: url('./stripe.svg');
}

.body {
  @include aspect-ratio(11, 3);
}

.container {
  @include position(absolute, 0);

  display: flex;
  border-top: 1.3px solid $white;
}

.content {
  display: flex;
  flex-shrink: 0;
  width: 72%;
  flex-direction: column;
  justify-content: space-around;
}

.title {
  display: flex;
  align-items: center;
  height: 50%;
  padding-left: 16px;
  font-family: 'HPSimplified';
  font-weight: 400;
  font-size: 10px;
  color: $white;
}

.button {
  display: flex;
  align-items: center;
  height: 50%;
  padding-left: 16px;
  font-family: 'GothamPro';
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  text-transform: uppercase;
  color: $white;
  background-color: $danger;
  border-top: 1px solid $white;

  @include fluid($breakpoint-m, $breakpoint-xl, 14px, 16px, font-size);
}

.extra {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 28%;
  padding: 8px;
  cursor: pointer;
  font-family: 'GothamPro';
  font-weight: 700;
  border-left: 1px solid $white;
  color: $white;

  &::before {
    @include position(absolute, 6);

    content: '';
  }

  &:hover {
    background-color: $danger;
  }
}
