@import '../../../styles/vars';
@import '../../../styles/mixins';

.section {
  background-color: $danger;
  @include fluid(300px, $breakpoint-xl, 22px, 30px, padding-top);
  @include fluid(300px, $breakpoint-xl, 20px, 30px, padding-left);
  @include fluid(300px, $breakpoint-xl, 20px, 30px, padding-right);
  @include fluid(300px, $breakpoint-xl, 22px, 30px, padding-bottom);


  @include media-breakpoint-up(xl) {
    @include fluid($breakpoint-xl, $breakpoint-4xl, 76px, 118px, padding-top);
    @include fluid($breakpoint-xl, $breakpoint-4xl, 56px, 70px, padding-left);
    @include fluid($breakpoint-xl, $breakpoint-4xl, 56px, 70px, padding-right);
    @include fluid($breakpoint-xl, $breakpoint-4xl, 76px, 118px, padding-bottom);
  }

  @media screen and (max-width: 480px) {
    padding: 0px;
  }
  overflow: hidden;
}

.container {
  position: relative;
  background-color: $black;
  @include fluid(300px, $breakpoint-xl, 24px, 52px, padding-top);
  @include fluid(300px, $breakpoint-xl, 24px, 78px, padding-left);
  @include fluid(300px, $breakpoint-xl, 24px, 78px, padding-right);
  @include fluid(300px, $breakpoint-xl, 24px, 30px, padding-bottom);

  @include media-breakpoint-up(xl) {
    padding: 52px 78px;
  }

  &::before,
  &::after {
    width: 14px;
    height: 90%;

    background-image: url('./stripe.svg');
    background-size: contain;
    content: '';
  }

  &::before {
    @include position(absolute, null null 0 -14px);
  }

  &::after {
    @include position(absolute, null -14px 0 null);
  }

  @media screen and (max-width: 480px) {
    padding: 20px;
  }
}

.tabs {
  width: 100%;
  margin-bottom: 80px;
  max-width: 560px;

  @media screen and (max-width: 480px) {
    margin-bottom: 40px;
  }
}

.tabsHeader {
  padding: 10px;
  text-transform: uppercase;
  border: 1px solid $danger;
  border-bottom: none;
  color: $white;
  display: flex;
  flex-direction: row;
  align-items: baseline;

  @media screen and (max-width: 480px) {

  }
}

.tabsTitle {
  font-family: 'GothamPro';
  font-weight: 700;
  letter-spacing: 4.6px;
  font-size: 46px;

  @media screen and (max-width: 480px) {
    font-size: 24px;
  }
}

.games {
  display: inline-flex;
  flex-flow: row;
  flex-wrap: nowrap;
  margin-left: 2em;
}

.gamesItem {
  font-family: 'GothamPro';
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 13px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 1.6px;
  text-transform: uppercase;
  color: #FFFFFF;
  margin-right: 1.2em;
  cursor: pointer;
  transition: color .3s;

  &:hover {
    color: $danger
  }
}

.gamesItemActive {
  color: $danger
}

.ratings {
  max-height: 414px;
  overflow-y: scroll;
  padding-right: 14px;

  &::-webkit-scrollbar-track {
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 45%, #4a4a4a 50%, $black 61%, $black 100%);
  }

  &::-webkit-scrollbar {
    width: 7px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $danger;
    border-radius: 24px;
    height: 54px;
  }
}


@media screen and (max-width: 760px) {
  .tabsHeader {
    display: block;
  }
  .games {
    margin-left: 0;
    margin-top: 20px;
    margin-bottom: 10px;
  }
}