body {
  font-family: 'HPSimplified', sans-serif;
  font-size: $base-font-size;
  font-weight: 400;
  background-color: $white;
}


html {

  -webkit-font-smoothing: antialiased;
}