@import '../../styles/_vars';

.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.col {
  flex: 0 0 auto;
  white-space: nowrap;
}

.quantity {
  display: inline-flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  vertical-align: middle;
}

.quantityBtn {
  width: 24px;
  height: 24px;
  padding: 0;
  background: transparent;
  border: 0;
  position: relative;
  outline: none;
  margin: 0 6px;
  cursor: pointer;
}

.quantityBtn:disabled {
  opacity: .3 !important;
  cursor: not-allowed;
}

.quantityBtnPlus::before,
.quantityBtnPlus::after,
.quantityBtnMinus::before {
  content: "";
  display: block;
  position: absolute;
  background-color: #fff;
  transition: background-color .3s;
}

.quantityBtnPlus::before,
.quantityBtnMinus::before {
  width: 13px;
  height: 1px;
  top: 50%;
  left: 50%;
  margin-left: -6px;
}

.quantityBtnPlus::after {
  width: 1px;
  height: 13px;
  top: 50%;
  margin-top: -6px;
  left: 50%;
  margin-left: 0px;
}

.quantityBtn:hover::before,
.quantityBtn:hover::after {
  background-color: $danger;
}

.quantityBtn:disabled::before,
.quantityBtn:disabled::after {
  background-color: #fff;
}

.input {
  height: 24px;
  border: 1px solid #979797;
  background-color: transparent;
  outline: none;
  color: #fff;
  font-family: inherit;
  padding: 0 1px;
  flex-grow: 0;
  flex-shrink: 0;
  max-width: 24px;
  flex-basis: 24px;
  text-align: center;
  line-height: 22px;
  padding-top: 1px;
}

.quantityCol {
  white-space: nowrap;
}

.label {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 13px;
  color: #6D7278;
  text-transform: none;
  vertical-align: middle;
}
.sizes {
  display: inline-flex;
  margin-left: 5px;
}
.sizeItem {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  overflow: hidden;
  width: 30px;
  height: 30px;
  cursor: pointer;

  input {
    position: absolute;
    left: -99em;
  }

  &:active {
    transform: translateX(1px)
  }
}

.sizeLabel {
  line-height: 30px;
  text-align: center;
  text-transform: uppercase;
  background-color: #000;
  font-size: 16px;
  display: block;
  transition: 
    color .3s,
    background .3s;

  &:hover {
    background-color: $danger;
    color: #fff !important;
  }
}
.sizeItem input:checked + .sizeLabel {
  background-color: #000;
  color: $danger !important;
}