@import '../../styles/vars';

.game {
  padding-top: $headerHeight;
  background-color: $black;

  iframe {
    width: 100%;
    height: calc(100vh - #{$headerHeight});
    border: none;
  }
}

