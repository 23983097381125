.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.label {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 13px;
  color: #6D7278;
  text-transform: none;
  vertical-align: middle;
  margin-right: 16px;
  
}

.select {
  width: 100%;
  flex-grow: 1;
  flex-shrink: 0;
  max-width: 151px;
  overflow: hidden;
  position: relative;

  & + & {
    margin-left: 10px;
  }

  select {
    width: 110%;
    width: calc(100% + 20px);
    height: 26px;
    line-height: 26px;
    padding: 0 25px 0 10px;
    color: #6D7278;
    background-color: transparent;
    outline: none;
    border: 0;
    border-bottom: 1px solid #404040;
    font-family: inherit;
    font-size: 14px;

    &:focus {
      border-bottom-color: lighten(#404040, 20%);
    }
  }

  option {
    color: #000
  }

  &::before {
    background-image: url("data:image/svg+xml,%3Csvg width='10' height='5' viewBox='0 0 10 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L5 4L9 1' stroke='%23EA002A'/%3E%3C/svg%3E");
    content: "";
    width: 10px;
    height: 5px;
    position: absolute;
    top: 50%;
    right: 5px;
    margin-top: -3px;
  }
}