@import '../../styles/vars';
@import '../../styles/mixins';

.menu {
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  @include fluid($breakpoint-xl, $breakpoint-4xl, 626px, 873px, width);
  @include fluid($breakpoint-xl, $breakpoint-4xl, 72px, 88px, height);
  border: 1px solid $white;
}

.wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding-left: em(12);
}

.item {
  display: flex;
  align-items: center;
  width: 25%;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &:hover {
    .label {
      font-weight: 700;
    }
  }
}

.label {
  display: inline-block;
  margin-left: 10px;
  font-family: 'GothamPro';
  @include fluid($breakpoint-xl, $breakpoint-4xl, 10px, 12px, font-size);
  @include fluid($breakpoint-xl, $breakpoint-4xl, 13px, 20px, line-height);
  font-weight: 400;
  color: $white;
  transition: transform 0.3s;
}

.active {
  .label {
    font-weight: 700;
    transform: scale(1.4) translateX(8px);
  }
}

.icon {
  display: inline-block;
  width: 32px;
  height: 32px;
  stroke: $white;

  @include media-breakpoint-up(2xl) {
    width: 42px;
    height: 42px;
  }
}

.button {
  display: inline-block;
  background: $white;
  cursor: pointer;
  overflow: hidden;
  flex-shrink: 0;
}

.buttonIcon {
  display: inline-block;
  @include fluid($breakpoint-xl, $breakpoint-4xl, 72px, 88px, width);
  height: 100%;
}
