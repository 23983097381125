@import '../../styles/vars';
@import '../../styles/mixins';

.wrapper {
  padding-top: $headerHeight;
  background-color: $black;
}

.sectionIntro {
  height: calc(100vh - #{$footerHeight} - 60px);
  @include fluid($breakpoint-xl, $breakpoint-4xl, 60px, 92px, padding-top);
  position: relative;
  margin-bottom: 250px;
}

.textBlock {
  position: relative;
  z-index: 1;
  @include fluid($breakpoint-xl, $breakpoint-4xl, 10px, 62px, margin-left);
  @include fluid($breakpoint-xl, $breakpoint-4xl, 10px, 62px, margin-right);
}

.title {
  margin-top: 0;
  @include fluid($breakpoint-xl, $breakpoint-4xl, 20px, 10px, margin-bottom);
  font-family: 'GothamPro';
  font-weight: 700;
  @include fluid($breakpoint-xl, $breakpoint-4xl, 97px, 120px, font-size);
  @include fluid($breakpoint-xl, $breakpoint-4xl, 105px, 118px, line-height);
  @include fluid($breakpoint-l, $breakpoint-xl, 60px, 97px, font-size);
  @include fluid($breakpoint-l, $breakpoint-xl, 69px, 105px, line-height);
  @include fluid($breakpoint-m, $breakpoint-l, 46px, 60px, font-size);
  @include fluid($breakpoint-m, $breakpoint-l, 49px, 69px, line-height);

  color: $white;
}

.description {
  max-width: 430px;
  font-family: 'HPSimplified';
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  color: $white;

  @include fluid($breakpoint-l, $breakpoint-xl, 14px, 16px, font-size);
  @include fluid($breakpoint-l, $breakpoint-xl, 27px, 27px, line-height);
}

.bg {
  @include position(absolute, 30.45% 0 -45.04% -13.84%);
  background-image: url('../../assets/images/bg.png');
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
}

.fg {
  position: absolute;
  top: 100%;
  left: 0;
  background-image: url('../../assets/images/svg/cross.svg');
  background-repeat: no-repeat;
  background-size: 504px;
  background-position: 100% 0;
  width: 320px;
  height: 245px;

  @include media-breakpoint-down(s) {
    width: 128px;
    height: 205px;
    background-size: cover;
    -webkit-transform: scale(0.6);
    transform: scale(0.9);
  }
}

.sectionCards {
}

.sectionCardsContainer {
  position: relative;
  max-width: 1920px;
  height: 100%;
  margin: 0 auto;
}

.sectionCardsTextBlock {
  position: absolute;
  top: 12%;
  @include fluid($breakpoint-xl, $breakpoint-4xl, 530px, 1000px, left);
  @include fluid($breakpoint-m, $breakpoint-l, 30px, 667px, left);
  @include fluid($breakpoint-l, $breakpoint-xl, 30px, 800px, left);
  max-width: 300px;
}

.sectionCardsTitle {
  margin-bottom: 0;
  font-family: 'GothamPro';
  font-weight: 700;
  @include fluid($breakpoint-xl, $breakpoint-4xl, 76px, 90px, font-size);
  @include fluid($breakpoint-l, $breakpoint-xl, 46px, 60px, font-size);
  @include fluid($breakpoint-l, $breakpoint-xl, 56px, 118px, line-height);
  line-height: 82px;
  color: $white;
}

.sectionCardsDescription {
  font-family: 'HPSimplified';
  font-weight: 400;
  font-size: 16px;
  @include fluid($breakpoint-l, $breakpoint-xl, 14px, 16px, font-size);
  line-height: 27px;
  color: $white;
}

.cardsWrapper {
  padding-top: 418px;
  padding-bottom: 100px;
  overflow-x: auto;
  overflow-y: hidden;

  @include fluid($breakpoint-m, $breakpoint-xl, 0px, 100px, padding-bottom);
}

.cards {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;


  @include media-breakpoint-down(xl) {
    width: calc(100vw * 1.1);
  }

  @include media-breakpoint-down(l) {
    width: calc(100vw * 1.4);
  }

  @include media-breakpoint-down(m) {
    width: calc(100vw * 1.6);
  }

  @include media-breakpoint-down(s) {
    width: calc(100vw * 2.5);
  }

  @include media-breakpoint-down(xs) {
    width: calc(100vw * 2.8);
  }
}

.item {
  position: relative;
  flex-shrink: 0;
  @include fluid($breakpoint-xl, $breakpoint-4xl, 264px, 346px, width);
  @include fluid($breakpoint-xl, $breakpoint-4xl, 380px, 514px, height);

  @include fluid($breakpoint-xl, $breakpoint-4xl, 10px, 22px, margin-left);
  @include fluid($breakpoint-xl, $breakpoint-4xl, 10px, 22px, margin-right);

  @include fluid($breakpoint-l, $breakpoint-xl, 245px, 264px, width);
  @include fluid($breakpoint-l, $breakpoint-xl, 331px, 380px, height);

  @include fluid($breakpoint-m, $breakpoint-l, 230px, 245px, width);



  &::after {
    @include position(absolute, null null -20px 0);
    width: 100%;
    height: 20px;
    content: '';
    transition: opacity 0.35s;
    opacity: 0;
  }

  &:hover {
    &::after {
      background: repeating-linear-gradient(
        45deg,
        $danger,
        transparent 1px,
        transparent 11px
      );
    }
  }
}

.slider {
  padding-bottom: 100px;

  @include media-breakpoint-down(l) {
    padding-bottom: 0;
  }
}

.slide1 {
  display: flex;
  justify-content: space-between;


  @include media-breakpoint-down(l) {
    padding: 0 em(16px);
  }

  @include media-breakpoint-up(l) {
    padding: 0 em(16px );
  }

  @include media-breakpoint-up(xl) {
    padding-left: 0;
    padding-right: em(16px);
  }


}

.slideLeft {
  width: 50%;

  @include media-breakpoint-down(l) {
    display: none;
  }

  @include media-breakpoint-up(2xl) {
    width: 43%;
  }

  @include media-breakpoint-up(3xl) {
    width: 49%;
  }

  @include media-breakpoint-up(4xl) {
    width: 41%;
  }
}

.slideRight {
  width: 48%;

  @include media-breakpoint-down(l) {
    flex-grow: 1;
    margin-bottom: 25px;
  }

  @include media-breakpoint-up(2xl) {
    width: 55%;
  }

  @include media-breakpoint-up(3xl) {
    width: 49%;
  }

  @include media-breakpoint-up(4xl) {
    width: 57%;
  }
}

.bg1 {
  width: 100%;
}

.slideTitle {
  @include fluid($breakpoint-xl, $breakpoint-4xl, 88px, 228px, margin-top);
  @include fluid($breakpoint-l, $breakpoint-xl, 30px, 80px, margin-top);
  font-family: 'GothamPro';
  font-weight: 700;
  @include fluid($breakpoint-xl, $breakpoint-4xl, 60px, 90px, font-size);
  @include fluid($breakpoint-l, $breakpoint-xl, 46px, 76px, font-size);
  @include fluid($breakpoint-m, $breakpoint-l, 33px, 56px, font-size);
  @include fluid($breakpoint-m, $breakpoint-l, 56px, 56px, line-height);
  line-height: 70px;
  color: $white;
}

.slideDescription {
  max-width: 330px;
  font-family: 'HPSimplified';
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  color: $white;

  @include fluid($breakpoint-l, $breakpoint-xl, 25px, 40px, margin-top);
  @include fluid($breakpoint-l, $breakpoint-xl, 14px, 16px, font-size);
  @include fluid($breakpoint-m, $breakpoint-l, 14px, 14px, font-size);
}

.chart {
  max-width: 100%;

  @include fluid($breakpoint-xl, $breakpoint-4xl, 10px, 190px, margin-top);
}
.chart + .slideTitle,
.screenshot + .slideTitle {
  margin-top: 0;
}

.screenshot {
  width: 100%;
  margin: 50px 0;
}

.box {
  position: relative;
  display: flex;
  width: 338px;
  height: 114px;
  margin-top: 20px;

  @include media-breakpoint-down(l) {
    margin-top: 40px;
  }

  @include media-breakpoint-down(s) {
    width: 100%;
  }
}

.boxWrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
}

.boxCaption {
  padding: 9px 12px;
  font-family: 'HPSimplified';
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  border-top: 1px solid $white;
  border-left: 1px solid $white;
  border-right: 1px solid $white;
  color: $white;
  flex-grow: 1;
}

.boxSquare {
  width: 114px;
  height: 100%;
  padding: 14px;
  flex-shrink: 0;
  border-top: 1px solid $white;
  border-right: 1px solid $white;
  border-bottom: 1px solid $white;
}

.boxPattern {
  height: 100%;
}

.linkButton {
  font-size: 18px;
  padding-left: 15px;
  padding-right: 15px;
  height: 56px;
  text-decoration: none;
  position: relative;
  z-index: 1;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-align-items: center;
  align-items: center;
  margin: 0;
  padding: 0;
  border: 1px solid #fff;
  background-color: initial;
  cursor: pointer;
  outline: 0;
  font-family: GothamPro;
  overflow: hidden;
  -webkit-tap-highlight-color: transparent;
}

.buttonText {
  font-weight: 700;
  text-transform: uppercase;
  color: rgb(234, 0, 42);
  padding-left: 14px
}

:global(.isMobile) {
  .wrapper {
    overflow: hidden;
  }
}
