@import '../../styles/vars';
@import '../../styles/mixins';

.unsubscribe {
  height: calc(100vh - 101px);
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: relative;
  padding-top: 70px;
  background-color: #ea002a;
  overflow: hidden;
}

.text {
  font-size: em(60px);
  color: #ffffff;
  z-index: 1;
}

.bg {
  position: absolute;
  top: 30.45%;
  left: 36.55%;
  right: -11.56%;
  bottom: -8.8%;
  background-image: url('../../assets/images/guy.png');
  background-size: contain;
  background-repeat: no-repeat;

  @include media-breakpoint-up(m) {
    left: 63.55%;
  }

  @include media-breakpoint-up(xl) {
    top: 12.45%;
    left: 66.55%;
    right: -1.56%;
    bottom: -8.8%;
  }

  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (-webkit-min-device-pixel-ratio: 2) {
    top: 50.45%;
  }
}
