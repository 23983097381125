@import '../../../styles/vars';
@import '../../../styles/mixins';

.buttons {
  display: flex;
  flex-shrink: 0;
}

.button {
  position: relative;
  z-index: 1;
  display: inline-flex;
  align-items: center;
  width: 50%;
  margin: 0;
  padding: 0;
  outline: 0;
  border: 1px solid $danger;
  background-color: transparent;
  outline: 0;
  font-family: 'GothamPro';
  text-decoration: none;
  text-align: left;
  color: $black;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  &:first-child {
    border-right: none;
  }
}

.disabled {
  background: green;
}

.text {
  flex-grow: 1;
  font-weight: 700;
  text-transform: uppercase;
}

.counter {
  display: inline-block;
  padding: 0 15px;
  font-family: 'HPSimplified';
  font-size: 20px;
  font-weight: 700;
  line-height: 48px;
  color: $black;
  border-left: 1px solid $danger;

  @include media-breakpoint-up(xl) {
    font-size: 31px;
  }
}

.size_m {
  font-size: 11px;
  height: 48px;

  @include media-breakpoint-up(xl) {
    font-size: 14px;
  }

  .text {
    padding: 0 10px;
    letter-spacing: 1.6px;
    line-height: 12px;
  }
}

.active {
  position: relative;
  background-color: $danger;
  color: $white;

  &::after {
    content: '';
    position: absolute;
    bottom: -15px;
    width: 100%;
    height: 15px;
    background: repeating-linear-gradient(
      45deg,
      $danger,
      transparent 1px,
      transparent 7px
    );
  }

  .counter {
    border-color: $white;
    color: $white;
  }
}
