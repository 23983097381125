
@import '../../styles/_vars';
.root {
  position: relative;
  margin-bottom: 60px;
}

.image {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  img {
    display: none;
  }

  &::before {
    content: "";
    display: block;
    padding-top: 66%;
    box-sizing: border-box;
  }

  &::after {
    // content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $danger;
    opacity: .75;

    @supports (mix-blend-mode: multiply) {
      mix-blend-mode: multiply;
      opacity: 1;
    }
  }
}
.footerWrapper {
  background-color: #000;
  padding: 15px;
  box-sizing: border-box;
  position: relative;
}
.footer {
  position: relative;
  z-index: 1;
  box-sizing: border-box;
  transform: translateZ(0);

  &::before,
  &::after {
    display: block;
    position: absolute;
    content: "";
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 10px 100%, 0% calc(100% - 10px));
  }

  &::before {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
  }

  &::after {
    top: 1px;
    left: 1px;
    right: 1px;
    bottom: 1px;
    background-color: #000;
  }
}

.footerInner {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
}

.footerAside {
  flex: 0 0 46%;
  min-width: 46%;
  box-sizing: border-box;
  border-left: 1px solid #fff;
  padding: 10px;
  position: relative;
}

.footerContent {
  box-sizing: border-box;
}

.name {
  text-transform: uppercase;
  font-weight: bold;
  font-family: 'GothamPro';
  border-bottom: 1px solid #fff;
  padding: 5px 10px 10px 10px;
  box-sizing: border-box;
  font-size: 14px;
}

.stock {
  padding: 10px;
}
.nameInner {
  display: block;
  letter-spacing: 0.157em;
  min-height: 1.286 * 3em;
  line-height: 1.286;
  font-size: 14px;


  @media screen and (max-width: 960px) {
    font-size: 12px;
  }
}

.btnWrapper {
  padding-top: 15px;
  display: block;
  box-sizing: border-box;
  transform: translateY(-68px);
  opacity: 0;
  position: absolute;
  bottom: 15px;
  left: 15px;
  right: 15px;
  transition:
    transform .3s ease-out,
    opacity .3s;
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 10px 100%, 0% calc(100% - 10px));
  cursor: pointer;

  &::before,
  &::after {
    position: absolute;
    display: block;
    content: "";
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 10px 100%, 0% calc(100% - 10px));
  }

  &::before {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
  }

  &::after {
    top: 1px;
    left: 1px;
    right: 1px;
    bottom: 1px;
    background-color: $danger;
    transition: background .3s;
  }

  &:hover::after{
    background-color: darken($danger, 10%);
  }

  &:active::after{
    background-color: darken($danger, 20%);
  }
}

.btn {
  display: block;
  position: relative;
  width: 100%;
  border: 0;
  background-color: rgba(0, 0, 0, 0);
  color: #fff;
  font-family: 'GothamPro';
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 19px;
  letter-spacing: 0.11em;
  text-transform: uppercase;
  height: 53px;
  outline: none;
  cursor: pointer;
  z-index: 1;

  @media screen and (max-width: 1200px) {
    font-size: 16px;
  }

  @media screen and (max-width: 768px) {
    font-size: 12px;
  }
}

.root:hover .btnWrapper {
  transform: translateY(53px);
  opacity: 1;
}

.root.isSelected .btnWrapper {
  transform: translateY(-100%);
}

.bage {
  display: inline-block;
  position: absolute;
  right: -1px;
  top: 10px;
  padding: 8px 10px;
  background-color: #000;
  color: #fff;
  font-size: 12px;
  z-index: 1;
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 6px 100%, 0% calc(100% - 6px));
  transform-origin: right top;
  transform: rotateZ(-90deg);
  opacity: 0;
  transition: 
    transform .2s ease-out,
    opacity .3s;
}
.root.isSelected .btnWrapper {
  opacity: 0;
}

.root.isSelected .bage {
  transform: rotateZ(0deg);
  opacity: 1
}

.stockLabel {
  font-size: 14px;
  color: #fff;
  margin-bottom: 1.2em;
}
.stockText {
  color: $danger;
  font-weight: 700;
  font-family: 'HPSimplified';
  font-size: 18px;
}

.priceInner {
  display: flex;
  flex-direction: column;
  top: 5px;
  left: 15px;
  bottom: 5px;
  right: 0;
  justify-content: space-between;
  position: absolute;
}

.priceLabel {
  font-size: 14px;
  letter-spacing: 0.157em;
  line-height: 1.286;
  font-weight: bold;
}

.priceText {
  font-size: 36px;
  line-height: 47px;
  font-weight: bold;
  color: $danger;

  @media screen and (max-width: 768px) {
    font-size: 24px;
    line-height: 30px;
  }
}

.root.isDisabled {
  opacity: .65;
  filter: grayscale(100%);
}

.root.isDisabled .footerAside::before {
  content: "";
  display: block;
  position: absolute;
  top: 13px;
  right: 13px;
  bottom: 13px;
  left: 13px;
  background-image: linear-gradient(45deg, 
  #000000 48%,
  #ffffff 48%, 
  #ffffff 50%,
  #000000 50%, 
  #000000 98%, 
  #ffffff 98%, 
  #ffffff 100%);
  background-size: 35px 35px;
}
