@import '../../styles/_vars';

.root {
  color: #fff;
  min-height: 100vh;
  min-height: calc(100vh - 150px);
  padding-bottom: 1px;
  padding-bottom: 90px;
  background: #000 url(./crossbg.svg) no-repeat;
  background-position:  90% 95%;
  background-position:  calc(100% - 61px) calc(100% - 76px);

}

.list {
  display: flex;
  flex-direction :row;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: flex-start;
  margin: 0 -18px 50px -18px;

  @media screen and (max-width: 1340px) {
    justify-content: center;
  }

  @media screen and (max-width: 680px) {
    margin-right: 0;
    margin-left: 0;
  }
}

.listItem {
  flex: 0 0 25%;
  max-width: 25%;
  padding: 0 18px;
  box-sizing: border-box;

  @media screen and (max-width: 1340px) {
    flex: 0 0 33.333%;
    max-width: 33.333%;
  }

  @media screen and (max-width: 1200px) {
    flex: 0 0 50%;
    max-width: 380px;
  }

  @media screen and (max-width: 680px) {
    flex: 0 0 100%;
    max-width: 380px;
  }
}

.header {
  box-sizing: border-box;
  overflow: hidden;
  padding-top: 128px;
  padding-left: 109px;
  margin-bottom: 60px;

  @media screen and (max-width: 380px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}


.listWrapper {
  margin: 0 24px;
  @media screen and (max-width: 680px) {
    margin: 0 auto;
  }
}

.text {
  max-width: 450px;
  font-size: 16px;
  line-height: 27px;

  & + & {
    margin-top: 0.5em;
  }
}

.title {
  font-size: 55px;
  line-height: 62px;
  margin: 0 0 .5em 0;
  padding: 0;
  font-family: 'GothamPro';

  @media screen and (max-width: 680px) {
    font-size: 32px;
  }
}

.danger {
  color: $danger
}

.cartHandler {
  opacity: 0;
  transform: translateX(-100%);
  position: fixed;
  top: 137px;
  width: 68px;
  left: 0;
  text-align: right;
  z-index: 100;
  background-image: linear-gradient(45deg,
    #000000 45.45%,
    $danger 45.45%,
    $danger 50%,
    #000000 50%,
    #000000 95.45%,
    $danger 95.45%,
    $danger 100%
  );
  background-size: 15.56px 15.56px;
  transition: 
    opacity .3s,
    transform .3s;

  button {
    background-color: $danger;
    min-width: 44px;
    height: 44px;
    border :0;
    cursor: pointer;
    outline: none;
    color: #fff;
    z-index: 100;
    box-sizing: border-box;
    border: 1px solid #FFFFFF;
    font-weight: bold;
    font-family: 'GothamPro';
    font-size: 20px;
    transition: 
      background .3s,
      border-color .3s;

    &:hover {
      background-color: darken($danger, 10%);
      border-color: rgba( #fff, .7 )
    }

    &:active {
      background-color: darken($danger, 10%);
      border-color: rgba( #fff, 0 );
    }
  }

  @media screen and (max-width: 380px) {
    top: 85px;
  }
}

.cartHandler.isVisible {
  opacity: 1;
  transform: translateX(0)
}