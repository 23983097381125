@import '../../styles/vars';

.root {
  //background-color: $danger;
  position: relative;
  &::before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    border-width: 3px;
    border-style: solid;
    border-color: adjust-color(#fff, $alpha: -0.9);
    border-top-color: $danger;
    border-radius: 50%;
    animation: Spin .75s linear infinite both;
  }

}

@keyframes Spin {
  to {
    transform: rotateZ(360deg)
  }
}