@import '../../../styles/vars';
@import '../../../styles/mixins';

.section {
  position: relative;
  overflow: hidden;
}

.container {
  position: relative;
  z-index: 1;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
}

.content {
  padding-left: em(16);
  padding-right: em(16);
  overflow: hidden;
  @include fluid(0px, $breakpoint-xl, 28px, 38px, padding-top);

  @include media-breakpoint-up(m) {
    padding-left: em(20);
    padding-right: em(20);
  }

  @include media-breakpoint-up(xl) {
    overflow: visible;
    @include fluid($breakpoint-xl, $breakpoint-4xl, 68px, 82px, padding-top);
  }
}

.header {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: em(84);

  @include media-breakpoint-up(m) {
    flex-direction: row;
  }
}

.head {
  position: relative;
  @include fluid(1024px, 1920px, 4px, 16px, margin-top);
  margin-bottom: 10px;

  @include media-breakpoint-up(m) {
    margin-bottom: 0;
  }
}

.title {
  position: relative;
  z-index: 1;
  margin: 0 0 0 10px;
  font-size: em(33);
  line-height: em(40, 33);
  font-family: 'GothamPro';
  font-weight: 700;
  letter-spacing: 3.3px;
  text-transform: uppercase;
  max-width: 300px;
  color: $black;

  @include media-breakpoint-up(m) {
    max-width: 400px;
    font-size: em(28);
    line-height: em(34, 28);
  }

  @include media-breakpoint-up(l) {
    max-width: 450px;
    font-size: em(33);
    line-height: em(40, 33);
  }

  @include media-breakpoint-up(xl) {
    max-width: 533px;
    font-size: em(46);
    line-height: em(54, 46);
    letter-spacing: 4.7px;
  }
}

.stripe {
  @include position(absolute, 0 100% null null);
  width: 600px;
  height: 68px;

  @include media-breakpoint-up(m) {
    height: 60px;
  }

  @include media-breakpoint-up(l) {
    height: 68px;
  }

  @include media-breakpoint-up(xl) {
    @include position(absolute, 0 calc(100% + 100px) null null);
    height: 89px;
  }
}

.slideButton {
  max-width: em(350);
  width: 100%;
  margin-top: percent(14, $breakpoint-xl);

  @include media-breakpoint-up(xl) {
    max-width: em(430);
  }

  @include media-breakpoint-up(2xl) {
    margin-top: percent(26, $breakpoint-2xl);
  }
}

.cards {
  display: flex;
  margin-bottom: em(90);
  overflow-x: scroll;

  @include fluid($breakpoint-xl, $breakpoint-4xl, 0px, 40px, padding-left);
  @include fluid($breakpoint-xl, $breakpoint-4xl, 0px, 40px, padding-right);

  @include media-breakpoint-up(xl) {
    overflow-x: visible;
  }
}

.item {
  width: 25%;
  box-sizing: border-box;
  padding: 0 15px;
  min-width: 290px;
  // @include fluid($breakpoint-xl, $breakpoint-4xl, 32px, 42px, margin-left);

  @include media-breakpoint-up(xl) {
    min-width: auto;
  }

  &:first-child {
    margin-left: 0;
  }
}

.fg {
  @include position(absolute, 88% null null -11%);
  width: 300px;
  height: 300px;
  background-image: url('../../../assets/images/svg/cross-black.svg');
  background-repeat: no-repeat;
  background-size: contain;

  @include media-breakpoint-up(xl) {
    left: -18%;
    @include fluid(1024px, 1920px, 380px, 396px, width);
    @include fluid(1024px, 1920px, 380px, 396px, height);
  }

  @include media-breakpoint-up(2xl) {
    top: 62%;
    left: 1.5%;
  }
}

.isEmpty {
  @include fluid($breakpoint-xl, $breakpoint-4xl, 270px, 510px, height);
}


.emptyBlock {
  padding: 20px 13px;
}
:global(.isMobile) {
  .cards {
    margin-bottom: -14px;
    padding-bottom: 54px;
  }
}
