@import '../../../styles/vars';
@import '../../../styles/mixins';

.root {
  background-color: $danger;
  color:#fff;
  overflow: hidden;
}

.row {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  &.reversed {
    flex-direction: row-reverse;
  }
}

.column {
  flex: 0 0 auto;
  position: relative;
  min-height: 580px;

  

  @media screen and (max-width: 480px) {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}
.title {
  max-width: 300px;
  margin: 0 0 25px;
  font-family: 'GothamPro';
  font-size: 33px;
  line-height: 40px;
  letter-spacing: 3.5px;
  font-weight: bold;
  text-transform: uppercase;
  color: $white;

  @include media-breakpoint-up(l) {
    max-width: 350px;
  }

  @include media-breakpoint-up(xl) {
    max-width: 505px;
    font-size: 46px;
    letter-spacing: 5px;
    line-height: 62px;
  }

  @media screen and (max-width: 480px) {
    font-size: 24px;
    line-height: 1.3;
  }
}

.columnTickets {
  background-color: #000;
  flex-grow: 0;
  flex-shrink: 0;
  width: 37.2%;
  box-sizing: border-box;
  padding: 80px 40px;
}

.columnShop {
  flex-grow: 0;
  flex-shrink: 0;
  width: 62.8%;
  padding: 80px 35px 30px 50px;
}

.loaderWrapper {
  margin: 30px 0;
  max-width: 414px;
  position:relative;
  background-color: #000;
}

.cards {
  display: flex;
  flex-direction: row;
  padding-bottom: 20px;
  margin: 0 -10px -20px -10px;

  @media screen and (min-width: 1024px) {

    max-width: 778px;
  }
}


.card {
  flex: 0 0 33.33%;
  padding: 0 10px;
}

.text {
  font-size: 16px;
  line-height: em(27, 16);
  margin-bottom: 1.5em;
}

.shopInner {
  padding-left: 60px;
  margin-bottom: 40px;
}

.arrow {
  width: 74px;
  height: 24px;
  display: inline-block;
  vertical-align: middle;
  margin-top: -0.2em;
}

.cross {
  position: absolute;
  bottom: 0%;
  right: 0%;
  background-image: url('../../../assets/images/svg/cross.svg');
  background-repeat: no-repeat;
  width: 200px;
  height: 200px;
  z-index: 0;

  @media screen and (max-width: 480px) {
    display: none;
  }
}

.checkInner {
  position: relative;
  z-index: 1;
}

.check {
  font-size: 13px;
  line-height: 27px;
  color: #636363;
  margin-bottom: 10px;
}

.stripe {
  position:absolute;
  bottom: 0;
  left:0;
  height: 40px;
  width: 40%;
}

.checkRate {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 27px;
  color: #636363;
}

@media screen and (max-width: 1396px) {
  .row {
    display: block
  }
  .columnTickets {
    width: 100%;
    padding: 80px 40px;
  }
  .columnShop {
    flex-grow: 0;
    flex-shrink: 0;
    width: 100%;
    padding: 80px 35px 30px 50px;
  }
}

@media screen and (max-width: 1024px) {
  .shopInner {
    padding-left: 0px;
    margin-bottom: 40px;
  }

  .title {
    max-width: none;
  }
  .card {
    flex: 0 0 50%;
    padding: 0 10px;

    &:nth-child(2) {
      display: none;
    }
  }
}

@media screen and (max-width: 768px) {
  .cards {
    overflow-x: scroll;
  }
  .cardsWrapp {
    overflow: hidden;
  }
  .card {
    flex: 0 0 300px;
    padding: 0 10px;
    margin-bottom: 40px;
    max-width: 380px;

    &:nth-child(2) {
      display: block;
    }
  }

  .columnTickets {
    padding: 60px 40px 100px 40px;
  }

  .columnShop {
    padding: 60px 40px;
  }
  
  .column {
    min-height: 100px;
  }

  .cross {
    transform: scale(.75);
    transform-origin: right bottom;
  }
}