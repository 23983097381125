@import '../../styles/_vars';

.root {
  box-sizing: border-box;
  padding: 20px 20px 20px 40px;
  position: relative;
  transition: 
    transform .3s ease-in-out,
    opacity .3s linear,
    background-color .3s;

    &:hover {
      background-color: lighten(#000, 4%);
    }
}
.footer{
  max-width: 442px;
  margin-top: 20px;
}
.isRemoved {
  opacity: 0;
  transform: translateX(-100%);
}

.image {
  box-sizing: border-box;
  background-size: cover;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 20%;
  min-width: 20%;
  position: relative;
  margin-right: 1em;
  background-position: center;
  
  &::after {
    // content: "";
    display: block;
    position: absolute;
    background-color: red;
    top: 0;
    left: 0;
    bottom :0;
    right: 0;
    mix-blend-mode: multiply;
  }

  &::before {
    content: "";
    display: block;
    box-sizing: border-box;
    padding-top: 100%;
  }

  img { display: none !important }
}

.inner {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.body {
  flex-grow :1
}

.name {
  font-family: 'GothamPro';
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 1.363;
  text-transform: uppercase;
  letter-spacing: 0.157em;
  margin-bottom: .5em;
}

.text {
  font-family: 'HPSimplified';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 1.833em;
  color: #636363
}

.price {
  flex-shrink: 0;
  flex-grow: 0;
  color: $danger;
  font-size: 18px;
  line-height: 16px;
  margin-right: 1em;
}

.removeBtn {
  position: absolute;
  width: 24px;
  height: 24px;
  left: 10px;
  top: 12px;
  background-color: rgba(0, 0, 0, 0);
  border: 0;
  cursor: pointer;
  outline: none;
  padding: 0;

  &::before,
  &::after {
    content: "";
    display: block;
    position: absolute;
    width: 14px;
    height: 1px;
    background-color: #fff;
    transform-origin: center;
    top: 50%;
    left: 50%;
    margin-left: -7px;
  }

  &::before {
    transform: rotateZ(-45deg)
  }

  &::after {
    transform: rotateZ(45deg)
  }

  &:hover::before,
  &:hover::after {
    background-color: $danger;
  }
}

.error {
  padding: 10px 0;
  font-size: 14px;
  color: $danger;
}

.hasError {
  box-shadow: inset 5px 0px 1px $danger
}