@import '../../styles/vars';
@import '../../styles/mixins';

.header {
  position: fixed;
  top: 0;
  z-index: 2020;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: $headerHeight;
  border-top: 1px solid $white;
  border-bottom: 1px solid $white;
}

.isSticky {
  .fill {
    transform: scaleX(1);
  }
}

.fill {
  @include position(absolute, 0 null null 0);
  z-index: -1;
  width: 100%;
  height: $headerHeight - 2;
  background-color: $black;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.4s ease;
  will-change: transform;
}

.left,
.right {
  display: flex;
}

.logo {
  display: flex;
  align-items: center;
  flex-grow: 0;
  padding: 0 em(20);
  text-decoration: none;
  cursor: pointer;

  &:last-child {
    border-left: 1px solid $white;
    fill: #fff;
  }

  @include media-breakpoint-up(3xl) {
    padding: 0 em(25);
  }

  @include media-breakpoint-up(4xl) {
    padding: 0 em(32);
  }
}

.nav {
  display: flex;
}

.button {
  font-weight: 700;
  background-color: transparent;
  outline: 0;
  border: 0;
  position: relative;

  &::before {
    content: "";
    display: block;
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 12px;
    top: 100%;
    left: 0;
    background-image: linear-gradient(45deg, #fff 10%, transparent 10%, transparent 50%, #fff 50%, #fff 60%, transparent 60%, transparent 100%);
    background-size: 7.07px 7.07px;
    transition: opacity .3s;
  }

  &:hover::before {
    opacity: 1
  }
}

.link,
.button {
  display: flex;
  align-items: center;
  padding: 0 em(20);
  font-family: 'GothamPro';
  font-size: 1em;
  color: $white;
  text-decoration: none;
  border-left: 1px solid $white;
  cursor: pointer;

  @include media-breakpoint-up(3xl) {
    padding: 0 em(25);
  }

  @include media-breakpoint-up(4xl) {
    padding: 0 em(32);
  }
}

.link {
  position: relative;
  font-weight: 400;

  &::before {
    content: "";
    display: block;
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 12px;
    top: 100%;
    left: 0;
    background-image: linear-gradient(45deg, #fff 10%, transparent 10%, transparent 50%, #fff 50%, #fff 60%, transparent 60%, transparent 100%);
    background-size: 7.07px 7.07px;
    transition: opacity .3s;
  }

  &:hover::before {
    opacity: 1
  }

  &.active {
    &::before {
      opacity: 1
    }
    &:after {
      content: '';
      position: absolute;
      bottom: -12px;
      width: 100%;
      height: 12px;
      left: 0;
    }
  }
}

.icon {
  display: inline-block;
}

.iconOmen {
  width: 102px;
  height: 16px;
}

.iconMicrosoft {
  width: 88px;
  height: 19px;
}

.iconSteam {
  width: 18px;
  margin-right: 10px;
}

.underline {
  position: absolute;
  bottom: -12px;
  height: 12px;
  background-image: url('./stripe.svg');
  transition: 0.5s all;
}

.patternButton {
  position: relative;

  span {
    position: relative;
    z-index: 1;
  }

  .stripe {
    position: absolute;
    width: 75%;
    height: 80%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
  }
}

.gameNav {
  display: flex;
  align-items: center;
  padding: 0 em(20);
  font-family: 'GothamPro';
  font-size: 1em;
  color: $white;
  text-decoration: none;
  border-left: 1px solid $white;
  cursor: pointer;
}

.gameLink {
  color: #fff;
  text-decoration: none;
  opacity: .75;
  transition: transform .3s, color .3s, opacity .3s;

  &:hover {
    color: #fff !important;
    opacity: 1
  }
}

.gameLinkActive {
  color: #fff;
  opacity: 1;
  font-weight: bold;
}

.isSticky .gameLinkActive {
  color: $danger
}

.gameLinkSeparator {
  display: inline-block;
  margin-left: .2em;
  margin-right: .2em;
  opacity: .8;
}