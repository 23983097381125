@import '../../styles/vars';
@import '../../styles/mixins';

.wrapper {
  overflow: hidden;
  padding-bottom: 20px;
}

.items {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  margin-bottom: -16px;
  padding-bottom: 40px;
}

.item {
  position: relative;
  flex-shrink: 0;
  @include fluid($breakpoint-xl, $breakpoint-4xl, 226px, 338px, width);
  @include fluid($breakpoint-xl, $breakpoint-4xl, 296px, 444px, height);
  margin: 0 40px;

  &::after {
    @include position(absolute, null null -20px 0);
    width: 100%;
    height: 20px;
    content: '';
    transition: opacity 0.35s;
    opacity: 0;
  }

  &:hover {
    .body,
    .frame {
      transform: scale(1);
      opacity: 1;
    }

    .image {
      transform: translate(-50%, -39%);
    }

    &::after {
      opacity: 1;

      background-image: url('./stripe.svg');
    }
  }
}

.inner_wrapper .item_wrapper:last-child {
  padding-right: 60px
}

.item_wrapper {
  outline: none;
}

.frame {
  position: relative;
  z-index: 0;
  stroke: $white;
  transform: scale(0);
  opacity: 0;
  transition: transform 0.35s, opacity 0.35s;
}

.card {
  @include position(absolute, 0);

  cursor: pointer;
}

.cover {
  @include aspect-ratio(113, 115);
}

.image {
  @include position(absolute, 50% null null 50%);
  transform: translate(-50%, -29%);
  transition: transform 0.35s;

  width: 100%;
  height: 80%;
  background-position: center center;
  background-repeat: no-repeat;
  max-width: 100%;
  background-size: contain;
}

.body {
  z-index: 1;
  transform: scale(0);
  opacity: 0;
  transition: transform 0.35s, opacity 0.35s;

  @include aspect-ratio(113, 32);
}

.container {
  @include position(absolute, 0 1px 0 1px);

  display: flex;
  border-top: 1px solid $white;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex-grow: 1;

  @include fluid($breakpoint-xl, $breakpoint-4xl, 8px, 12px, padding-top);
  @include fluid($breakpoint-xl, $breakpoint-4xl, 8px, 14px, padding-left);
  @include fluid($breakpoint-xl, $breakpoint-4xl, 8px, 14px, padding-right);
  @include fluid($breakpoint-xl, $breakpoint-4xl, 0px, 8px, padding-bottom);
}

.description {
  font-family: 'HPSimplified';
  font-weight: 400;
  @include fluid($breakpoint-xl, $breakpoint-4xl, 8.5px, 10px, font-size);
  color: $white;
}

.title {
  font-family: 'GothamPro';
  font-weight: 700;
  @include fluid($breakpoint-xl, $breakpoint-4xl, 16px, 18px, font-size);
  color: $danger;
  white-space: normal;
}

.link {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 30%;
  border-left: 1px solid $white;
  cursor: pointer;
  padding: 0 14px;

  &:hover {
    &::before {
      background-image: url('./stripe-2.svg');
    }
  }

  &::before {
    @include position(absolute, 6px);

    content: '';
  }
}

.icon {
  width: 44px;
  height: 26px;
}

.next {
  @include position(absolute, 44% -50px null null);
  @include fluid($breakpoint-xl, $breakpoint-4xl, 18px, 22px, width);
  @include fluid($breakpoint-xl, $breakpoint-4xl, 18px, 22px, height);

  stroke: $danger;
}
