.root {
  display: block;
}

.item {
  display: flex;
  flex-direction: rowl;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 12px;

  &.disabled {
    opacity: .65
  }
}

.icon {
  margin-right: 12px;
  margin-top: 3px;
}

.text {
  font-size: 13px;
  line-height: 2em;
}