@import '../../../styles/vars';
@import '../../../styles/mixins';

$footerHeight: 68px;

.card {
  cursor: pointer;

  &:hover {
    .footer {
      transform: translateY(-20px);
    }

    .image {
      background-color: transparent;
      background-blend-mode: normal;
    }
  }
}

.header {
  @include aspect-ratio(40, 13);
}

.wrapper {
  @include position(absolute, 0);

  display: flex;
  color: $white;
  border: 1px solid $white;
}

.box {
  display: flex;
  align-items: center;
  @include fluid($breakpoint-xl, $breakpoint-4xl, 19px, 22px, padding-left);
  @include fluid($breakpoint-xl, $breakpoint-4xl, 19px, 22px, padding-right);
  border-right: 1px solid $white;
}

.icon {
  @include fluid($breakpoint-xl, $breakpoint-4xl, 36px, 46px, width);
  @include fluid($breakpoint-xl, $breakpoint-4xl, 36px, 46px, height);
  stroke: $white;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  padding-top: 15px;
  padding-left: 12px;
  padding-right: 12px;
  @include fluid($breakpoint-xl, $breakpoint-4xl, 4px, 8px, padding-bottom);
}

.title {
  font-family: 'GothamPro';
  font-weight: 700;
  @include fluid($breakpoint-xl, $breakpoint-4xl, 16px, 20px, font-size);
}

.description {
  font-family: 'HPSimplified';
  font-weight: 400;
  font-size: 10px;
  line-height: 13px;
}

.frame {
  position: relative;
  background-color: $white;
  clip-path: polygon(100% 0, 100% 86%, 93% 100%, 0 100%, 0 0);
  z-index: 1;
}

.cover {
  @include aspect-ratio(40, 21);
}

.image {
  @include position(absolute, 0 1px 1px 1px);

  clip-path: polygon(100% 0, 100% 86%, 93% 100%, 0 100%, 0 0);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: $danger;
  background-blend-mode: multiply;
}

.footer {
  position: relative;
  z-index: 0;
  background-color: $white;
  clip-path: polygon(100% 0, 100% 74%, 93% 100%, 0 100%, 0 0);
  height: $footerHeight;
  transform: translateY(-72px);
  transition: transform 0.2s ease-in;
}

.button {
  position: absolute;
  top: 0;
  left: 1px;
  right: 1px;
  bottom: 1px;
  background-color: $danger;
  clip-path: polygon(100% 0, 100% 74%, 93% 100%, 0 100%, 0 0);
}

.buttonContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 31px 15px 0 21px;
}

.buttonText {
  font-family: 'GothamPro';
  font-weight: 700;
  font-size: 20px;
  text-transform: uppercase;
  color: $white;
}

.stripe {
  display: inline-block;
  width: 130px;
  height: 16px;
  margin-left: 8px;
}
