@import '../../../styles/vars';
@import '../../../styles/mixins';

.section {
  height: 100%;
  padding-bottom: 14px;
  background-color: $black;
  position: relative;
  overflow: hidden;

  @include media-breakpoint-up(xl) {
    padding-top: 54px;
    padding-bottom: 64px;
  }
}

.anchor {
  padding-top: 38px;
  background-color: $black;

  @include media-breakpoint-up(m) {
    padding-top: 68px;
  }
}

.header {
  display: flex;
  max-width: 1920px;
  @include fluid(0px, $breakpoint-m, 10px, 20px, padding-left);
  @include fluid(0px, $breakpoint-m, 10px, 20px, padding-right);

  @include media-breakpoint-up(xl) {
    justify-content: flex-end;
    margin: 0 auto 114px;
  }
}

.title {
  max-width: 290px;
  margin: 0;
  font-family: 'GothamPro';
  font-weight: 700;
  font-size: 46px;
  line-height: 62px;
  letter-spacing: 5px;
  text-transform: uppercase;
  color: $white;
}

.box {
  position: relative;
  display: flex;
  max-width: 320px;
  height: 102px;

  @media screen and (max-width: 480px) {
    max-width: calc(100% - 40px) !important;
    margin-right: auto !important;
    margin-left: auto !important;
  }
}

.box1 {
  display: none;

  @include media-breakpoint-up(m) {
    display: flex;
  }
}

.box2 {
  display: flex;
  margin-bottom: 30px;
  @include fluid(0px, $breakpoint-m, 10px, 20px, margin-left);
  @include fluid(0px, $breakpoint-m, 10px, 20px, margin-right);

  button > span {
    white-space: nowrap;
  }

  @include media-breakpoint-up(m) {
    display: none;
  }
}

.boxWrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
}

.boxCaption {
  padding: 9px 12px;
  font-family: 'HPSimplified';
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  border-top: 1px solid $white;
  border-left: 1px solid $white;
  border-right: 1px solid $white;
  color: $white;
}

.boxSquare {
  width: 102px;
  height: 100%;
  padding: 10px;
  flex-shrink: 0;
  border-top: 1px solid $white;
  border-right: 1px solid $white;
  border-bottom: 1px solid $white;

  @media screen and (max-width: 480px) {
    width: 45px;
  }
}

.boxPattern {
  height: 100%;
}
