@import '../../styles/vars';
@import '../../styles/mixins';

.root {
  position: fixed;
  top: $headerHeight;
  left: 0;
  z-index: 2021;
  display: block;
  width: 100%;
  height: calc(100% - #{$headerHeight});
  visibility: hidden;
  transition: visibility 200ms;
}

.sidenav {
  @include position(absolute, 0 null null null);
  z-index: 60;
  display: block;
  @include fluid($breakpoint-xl, $breakpoint-3xl, 400px, 646px, width);
  height: 100%;
  transform: translate3d(-100%, 0, 0);
  transition: transform 200ms;
  will-change: transform;

  @media screen and (max-width: 400px) {
    width: 100%;
  }
}

.left {
  left: 0;
}

.right {
  right: 0;
}

.backdrop {
  @include position(absolute, 0);

  z-index: 50;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 200ms;
}

.opened {
  visibility: visible;

  .sidenav {
    transform: translate3d(0, 0, 0);
  }

  .backdrop {
    opacity: 1;
  }
}

.close {
  @include position(absolute, 10px 10px null null);

  display: inline-block;
  cursor: pointer;
  line-height: 0;
  color: #4a4a4a;
  stroke: currentColor;
  z-index: 2;

  &:hover {
    color: $white;
  }
}

.wrapper {
  height: 100%;
  overflow-y: auto;
}
