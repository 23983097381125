@import '../../styles/_vars';

.root {
  max-width: 477px !important;
  position: absolute;
  outline: none !important
}

.body {
  background-color: $danger;
  background-image: url(./bg.svg);
  background-repeat: no-repeat;
  background-position: 90% 50%;
  background-position: calc(100% - 25px) 60px;
  clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0% 100%);
  
}

.title {
  padding: 27px 25px;
  font-family: 'GothamPro';
  font-style: normal;
  font-weight: bold;
  font-size: 29px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #FFFFFF;
  max-width: 50%;
  position: relative;
  margin-bottom: 50px;
}

.topStripes {
  height: 50px;
  width: 251px;
  margin-bottom: 15px;
  position: absolute;
  top:80px;
}

.text {
  padding: 0 25px;
  padding-bottom: 25px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  color: #FFFFFF;
  max-width: 251px;
  position: relative;

  a {
    color: #000;


    &:hover {
      color: #fff;
      text-decoration: none;
      opacity: .85;
    }
  }

  p {
    margin-bottom: .4em;
    margin-top: .4em;
  }

}


.isWide .title {
  max-width: 70%;
  padding-bottom: 1px;
  margin-bottom: 20px;
}

.isWide .text {
  max-width: 360px;
}

.isFull .text,
.isFull .title {
  max-width: none;
}
.footer {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  border: 2px solid #fff;
}

.btnWhite {
  height: 56px;
  line-height: 56px;
  padding: 0;
  outline: none;
  text-align: center;
  flex-grow: 1;
  background-color: #fff;
  border: 0;
  font-family: 'GothamPro';
  font-style: normal;
  font-weight: bold;
  font-size: 17.6px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 2.01143px;
  text-transform: uppercase;
  outline: none;
  cursor: pointer;
  flex-shrink: 0;

  &:hover {
    color: $danger;
  }
}

.btn {
  height: 56px;
  line-height: 56px;
  display: inline-block;
  padding: 0;
  outline: none;
  text-align: center;
  flex-grow: 1;
  background-color: $danger;
  border: 0;
  font-family: 'GothamPro';
  font-style: normal;
  font-weight: bold;
  font-size: 17.6px;
  line-height: 56px;
  text-align: center;
  letter-spacing: 2.01143px;
  text-transform: uppercase;
  text-decoration: none;
  color: #fff;
  cursor: pointer;
  transition: background .3s;

  &:hover {
    background-color: #000;
  }
}
