@import '../../styles/vars';

.checkbox {
  display: inline-flex;
  cursor: pointer;
  user-select: none;
  font-family: 'HPSimplified';
  font-weight: 400;
  line-height: 1;
}

.box {
  display: inline-flex;
  position: relative;
  flex: 0 0 auto;
  border-width: 1px;
  border-style: solid;
  border-color: #6d7278;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0.5px;
    height: 2px;
    margin: auto 2px;
    opacity: 0;
    transform: scaleX(0.4);
    border-radius: 1px;
    transition: transform 200ms ease, opacity 200ms ease;
  }
}

.indeterminate {
  background: $danger;
}

.control {
  position: absolute;
  opacity: 0;
  margin: 0;
  z-index: -1;
}

.icon {
  position: absolute;
  top: 50%;
  left: 50%;
  display: inline-block;
  line-height: 0;
  opacity: 0;
  transform: translate(-50%, calc(-50% - 4.5px));
  transition: transform 200ms, opacity 200ms;
  fill: $danger;
}

.text {
  vertical-align: top;
  padding-left: 10px;
  color: #6d7278;
}

.size_s {
  .box {
    width: 14px;
    height: 14px;
  }

  .icon {
    width: 8px;
    height: 8px;
  }

  .text {
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
  }
}

.size_m {
  .box {
    width: 24px;
    height: 24px;
  }
}

.size_l {
  .box {
    width: 34px;
    height: 34px;
  }

  .icon {
    width: 16px;
    height: 16px;
  }
}

.checked {
  .icon {
    opacity: 1;
    transform: translate(-50%, -50%);
  }

  .box {
    border-color: $white;
  }

  .text {
    color: $white;
  }
}
