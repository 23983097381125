@import '../../../styles/vars';
@import '../../../styles/mixins';

.button {
  position: relative;
  z-index: 1;
  display: inline-flex;
  align-items: center;
  margin: 0;
  padding: 0;
  border: 1px solid $white;
  outline: 0;
  background-color: transparent;
  cursor: pointer;
  font-family: 'GothamPro';
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;

  &::before {
    content: '';
    z-index: -1;
    opacity: 0;
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    background: $black;
    transform-origin: right;
    transform: scale(0, 1);
    transition: transform 0.5s, opacity 1s;
    transition-timing-function: cubic-bezier(0.910, 0.060, 1.000, 1.000);
  }

  &.semiFilled::before {
    opacity: 1;
    transform: scale(.45, 1);
    transform-origin: left;
  }

  &:hover::before {
    opacity: 1;
    transform: scale(1, 1);
    transition: transform 0.5s, opacity .7s;
    transform-origin: left;
  }
}

.disabled {
  background: none;
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #6d7278;
    top: 0;
    right: 0;
    z-index: -1;
  }
}

.text {
  font-weight: 700;
  text-transform: uppercase;
}

.size_xs {
  height: 44px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 12px;
  letter-spacing: 1.8px;

  .fingerPrint {
    height: calc(44px - 2px);
  }
}

.size_s {
  font-size: 12px;
  height: 52px;

  .fingerPrint {
    height: calc(52px - 2px);
  }
}

.size_m {
  font-size: 18px;
  padding-left: 15px;
  padding-right: 15px;
  height: 56px;

  .fingerPrint {
    height: calc(56px - 2px);
  }
}

.size_l {
  font-size: 18px;
}

.slide_red {
  &::before {
    background-color: $danger
  }
}

.slide_black {
  &::before {
    background-color: $black
  }
}

.fullWidth {
  width: 100%;
}

.icon {
  display: inline-block;
}

.iconPosition_right {
  .icon {
    margin-left: 16px;
  }
}

.withFingerPrint {
  padding-right: 0;
}

.fingerPrint {
  width: 46px;
  padding: 8px;
  margin-left: 20px;
  border-left: 1px solid $white;
}

.leftStripe {
  width: 50%;
}

.rightStripe {
  width: 50%;
  transform: scaleX(-1);
}

.stripe {
  position: absolute;
  left: 0;
}
