@import '../../styles/vars';

.root {
  display: block;
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  cursor:pointer;
  box-sizing: border-box;
  overflow: hidden;
  &.pending {
    cursor: not-allowed;
  }

  .spacer {
    flex-grow: 1;
  }

  input {
    visibility: hidden;
  }

  .name {
    flex-grow: 1;
    background: rgba(216, 216, 216, 0.1);
    height: 44px;
    padding-left: 20px;
    line-height: 44px;
    box-sizing: border-box;
    color:#fff;

    span {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 100%;
      display: inline-block;
    }
  }

  .btn {
    height: 44px;
    background: rgba(216, 216, 216, 0.2);
    flex-grow: 0;
    width: 44px;
    position: relative;
    background-image: url("data:image/svg+xml,%3Csvg width='13' height='12' viewBox='0 0 13 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9.67647 8.48436L9.02732 7.83783L6.60606 10.2496L6.60606 2.11765H5.68805L5.68805 10.2496L3.2668 7.83783L2.61765 8.48436L6.14706 12L9.67647 8.48436ZM12.5 0V0.705882L0.5 0.705882V0L12.5 0Z' fill='white'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center;
    transition: background .3s;
    cursor: pointer;

    &:hover {
      background-color: rgba(216, 216, 216, 0.1);
    }

    &:active {
      background-color: rgba(216, 216, 216, 0.05);
    }
  }
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)
}