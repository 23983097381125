@import '../../styles/vars';
@import '../../styles/mixins';

.footer {
  display: flex;
  justify-content: space-between;
  height: 66px;
  border-top: 1px solid $white;
  border-bottom: 1px solid $white;
  background-color: $black;
  overflow: hidden;

  @include media-breakpoint-up(xl) {
    height: $footerHeight;
  }
}

.left {
  display: flex;
  align-items: center;
  height: inherit;
}

.right {
  display: flex;
}

.logo {
  display: none;
  padding: 0 1em;

  svg {
    width: 140px;

    @include media-breakpoint-up(xl) {
      width: 146px;
    }
  }

  @include media-breakpoint-up(m) {
    display: inline-block;
  }
}

.secondaryLogo {
  display: flex;
  align-items: center;
  padding: 0 0.6em;
  text-decoration: none;

  @include media-breakpoint-up(m) {
    padding: 0 1.5em;
  }
}

.social {
  display: flex;
  height: 100%;
}

.socialLink {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 3em;
  padding: 0 0.6em;
  position: relative;
  font-family: 'GothamPro';
  font-size: 22px;
  font-weight: 700;
  color: $white;
  text-decoration: none;
  border-left: 1px solid $white;
  cursor: pointer;

  &:last-child {
    @media screen and (min-width: 400px) {
      border-right: 1px solid $white;
    }
  }

  @media screen and (max-width: 380px) {
    min-width: 40px;
    width: 40px;
  }

  &:hover {
    &::before {
      content: '';
      @include position(absolute, 50% null null 50%);
      transform: translate(-50%, -50%);
      z-index: 0;
      width: 80%;
      height: 80%;
      background-image: url('./stripe.svg');
    }
  }

  @include media-breakpoint-up(xl) {
    padding: 0 1.6em;
    min-width: 4.5em;
  }
}

.socialLinkText {
  z-index: 1;
}

.info {
  display: flex;
  flex-direction: column;
  border-left: 1px solid $white;
  border-right: 1px solid $white;

  @include media-breakpoint-up(xl) {
    min-width: 230px;
  }
}

.link {
  display: inline-flex;
  align-items: center;
  flex: 1;
  padding: 0 1.34em;
  font-family: 'HPSimplified';
  font-size: 12px;
  font-weight: 400;
  text-decoration: none;
  color: $white;
  cursor: pointer;
  position: relative;
  z-index: 2;

  &:first-child {
    border-bottom: 1px solid $white;
  }

  &::after {
    content: '';
    display: block;
    background-color: $danger;
    width: 0;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transition: 0.5s width;
  }

  &:hover::after {
    width: 100%;
  }
}
