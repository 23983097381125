@import '../../../styles/vars';
@import '../../../styles/mixins';

.card {
  position: relative;
  z-index: 1;
  cursor: pointer;

  &:hover {
    .footer {
      @include fluid-transform($breakpoint-xl, $breakpoint-4xl, -20px, -30px, transform, vertical);
    }
  }
}

.header {
  display: flex;
  color: $white;
  background-color: $white;
  clip-path: polygon(6% 0, 100% 0, 100% 100%, 0 100%, 0 20%);

  @include aspect-ratio(109, 38);
}

.wrapper {
  @include position(absolute, 1px);

  display: flex;
  clip-path: polygon(6% 0, 100% 0, 100% 100%, 0 100%, 0 20%);
}

.frame {
  position: relative;
  background-color: $white;
  clip-path: polygon(100% 0, 100% 85%, 92% 100%, 0 100%, 0 0);
}

.cover {
  @include aspect-ratio(109, 63);
}

.image {
  @include position(absolute, 0 1px 1px 1px);

  clip-path: polygon(100% 0, 100% 85%, 92% 100%, 0 100%, 0 0);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: $danger;
  background-blend-mode: multiply;
}


.content {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  padding: 12px;
}

.title {
  font-family: 'GothamPro';
  font-weight: 700;
  font-size: 16px;
  line-height: 15px;
  color: $white;
}

.nickName {
  font-family: 'HPSimplified';
  font-weight: 400;
  font-size: 10px;
  line-height: 13px;
  color: $white;
}

.stats {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  border-left: 1px solid $white;
}

.role {
  font-family: 'GothamPro';
  font-weight: 700;
  font-size: 16px;
}

.label {
  font-family: 'GothamPro';
  font-weight: 700;
  font-size: 10px;
  line-height: 9px;
  letter-spacing: 1.57143px;
  text-transform: uppercase;
  color: $white;
}

.mmr {
  font-family: 'HPSimplified';
  font-weight: 700;
  font-size: 26px;
  line-height: 18px;
  text-transform: uppercase;
  color: inherit;
}

.footer {
  position: relative;
  z-index: -1;
  background-color: $white;
  clip-path: polygon(100% 0, 100% 74%, 93% 100%, 0 100%, 0 0);
  @include fluid($breakpoint-xl, $breakpoint-4xl, 68px, 100px, height);
  @include fluid-transform($breakpoint-xl, $breakpoint-4xl, -70px, -106px, transform, vertical);

  transition: transform 0.2s ease-in;
}

.button {
  @include position(absolute, 0 1px 1px 1px);

  background-color: $danger;
  clip-path: polygon(100% 0, 100% 74%, 93% 100%, 0 100%, 0 0);
  text-decoration: none;
}

.buttonContent {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  height: 80%;
  padding: 0 18px;
}

.buttonText {
  font-family: 'GothamPro';
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase;
  @include fluid($breakpoint-xl, $breakpoint-4xl, 16px, 20px, font-size);
  color: $white;
}

.stripe {
  display: inline-block;
  width: 130px;
  height: 17px;
  margin-left: 8px;
}
