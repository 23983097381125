@import '../../styles/_vars';

.root {
  color: #fff;
  min-height: 100vh;
  min-height: calc(100vh - 101px);
  padding-bottom: 1px;
  padding-bottom: 90px;
  background: #000;
  background-position:  90% 95%;
  background-position:  calc(100% - 61px) calc(100% - 76px);
  padding-top: 140px;
  box-sizing: border-box;
  padding-left: 30px;
  padding-right: 65px;
}

.row {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: nowrap;
  width: 100%;
  box-sizing: border-box;
}

.column {
  flex: 0 0 auto;
  max-width: 100%;
  box-sizing: border-box;
}

.columnGrow {
  flex-grow: 1;
  flex-shrink: 1;
}

.profileColumn {
  flex-basis: 277px;
  max-width: 277px;
  margin-right: 58px;
  flex-grow: 0;
}

.contentColumn {
  flex-grow: 1;
  margin-right: 58px;
  flex-shrink: 1;
}

.asideColumn {
  flex-grow: 0;
  flex-basis: 30%;
  max-width: 311px;
}

.label {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 27px;
  color: #FFFFFF;
  margin-right: 5px;
}

.avatar {
  background-color: $danger;
  box-sizing: border-box;
  margin-bottom: 20px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-blend-mode: multiply;
  clip-path: polygon(100% 0, 100% 93%, 94% 100%, 0 100%, 0 0);
  &::before {
    content: "";
    display: block;
    box-sizing: border-box;
    padding-top: 92.5%
  }
}

.title {
  font-family: 'GothamPro';
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  color: #FFFFFF;
}

.section {
  margin-bottom: 40px;
  width: 100%;
}

.sectionHeader {
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
}

.link {
  color: $danger;
  font-size: 12px;
  cursor: pointer;
  outline: none;
  transition: color .3s;

  &:hover {
    color: darken($danger, 10%)
  }
}

.table {
  width: 100%;
  border-collapse: collapse;
  border: 0;
  margin-bottom: 40px;

  thead,tbody, tr {
    width: 100%;
    flex-grow:1;
  }

  th {
    font-weight: normal;
    text-align: left;
    vertical-align: bottom;
    padding-bottom: 20px;
    padding-left: 20px;

    &:first-child {
      padding-left: 0;
    }
  }

  th:last-child, td:last-child {
    width: 140px;
    text-align: right;
  }

  td {
    padding: 15px 20px;
  }

  td {
    background: rgba(216, 216, 216, 0.1);
    transition: background .3s;
  }

  tr:hover td {
    background: rgba(216, 216, 216, 0.15)
  }
}

.contentTitle {
  font-family: 'GothamPro';
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
}

.contentSubTitle {
  font-family: 'GothamPro';
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 27px;
  margin-bottom: 0.5em;
}

.contentLabel {
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 27px;
  color: #636363;
}
.contentText {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 27px;
}
.contentMeta {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 17px;
  color: #636363;
}
.price {
  color: $danger;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}

.loader {
  position: relative;
  width: 100%;
  height: 6px;
  line-height: 6px;
  margin :10px 0;
  text-align: center;
  font-weight: bold;
  overflow: hidden;

  span {
    position: relative;
    color: #fff;
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    opacity: .7;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(45deg, transparent 25%, $danger 25%, $danger 50%, transparent 50%, transparent 75%, $danger 75%, $danger 100%);
    background-size: 28.28px 28.28px;
    animation: stripeLoader 6s linear both infinite;
  }
}

.daily {
  border: 1px solid #404040;
  padding: 15px;
  margin-top: 20px;
  box-sizing: border-box;
}

.upload {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}
.upload .contentTitle {
  width: 100%;
  margin-bottom: 10px;
}
.uploadContent {
  flex-grow: 1;
  flex-shrink: 1;
  max-width: 60%;
  margin-right: 25px;
}

.uploadControl {
  flex-grow: 1;
  min-width: 30%;
  overflow: hidden;
}

.spinnerWrapper {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.asideText {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 27px;
  color: #636363;
}

.upcoming {
  margin: 20px 0;
}
.upcomingLabel {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 27px;
  margin-bottom: 10px;
}

.upcomingData {
  box-sizing: border-box;
  padding: 15px;
  border: 1px solid #404040;
  text-align: center;
  font-size: 20px;
  line-height: 1.3;
  margin-bottom: 20px;
}

.checksLink {
  color: inherit;
  text-decoration: none;
  display: inline-block;
  border-bottom:1px dashed rgba(#fff, .5);
  line-height: 1.3;
  transition: border .3s, color .3s;

  &:hover {
    color: $danger;
    border-bottom-color: transparent;
  }
}

@keyframes stripeLoader {
  to {
    background-position: 100% 0
  }
}

@media screen and (max-width: 1420px) {
  
  .row {
    .row{
      flex-wrap: wrap;
    }
  }

  .contentColumn {
    margin-right: 0;
    margin-left: 0;
  }

  .profileColumn {
    max-width: 25%;
    flex-basis: 25%;
    margin-right: 0;
  }

  .columnGrow {
    max-width: 75%;
    flex-basis: 75%;
    flex-grow: 0;
    padding-left: 60px;
  }

  .asideColumn {
    flex-grow: 0;
    flex-basis: 100%;
    max-width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .root {
    padding-top: 120px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .row {
    flex-direction: column;
  }

  .column {
    width: 100%;
    max-width: 100%;
    flex-basis:100%;
    margin-right: 0;
    margin-left: 0;
    padding-left: 0;
  }

  .avatar {
    max-width: 300px;
  }

  .upload {
    display: block;
  }

  .uploadContent {
    margin-bottom: 20px;
    margin-right: 0;
  }
}


@media screen and (max-width: 1200px) {
  .contentTitle {
    font-size: 28px;
  }

  .title {
    font-size: 32px;
  }
}

@media screen and (max-width: 960px) {
  .contentTitle {
    font-size: 24px;
  }

  .title {
    font-size: 26px;
  }
}

.ratingSpinner {
  margin: 20px 0;
}

@media screen and (max-width: 680px) {
  .table {
    display: block;
    width: 100%;

    tbody, thead, tr, td, th {
      display: block;
    }

    td {
      padding: 10px 20px;

      &:last-child {
        text-align: left;
        width: auto;
      }
    }

    tr {
      margin-bottom: 10px;
      clip-path: polygon(100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 0);
    }

    th {
      display: none;
    }

    th:first-child {
      display: block;
    }

    .price {
      font-size: 16px;
    }

  }
}