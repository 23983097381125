@import '../../styles/vars';
@import '../../styles/mixins';

.menu {
  display: none;
  background-color: $white;
  z-index: 2021;

  @include position(fixed, 0);
}

.open {
  display: block;
}

.button {
  display: inline-block;
  height: 60px;
  background-color: $danger;
  border: 0;
  font-size: 24px;
  font-weight: bold;
  font-family: inherit;
  text-transform: uppercase;
  padding: 0 40px;
  color: #fff;
  margin-bottom: 20px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  border-bottom: 1px solid $black;
}

.content {
  position: relative;

  @include fluid(300px, $breakpoint-xl, 20px, 48px, padding-top);
  @include fluid(300px, $breakpoint-xl, 14px, 48px, padding-left);
  @include fluid(300px, $breakpoint-xl, 14px, 48px, padding-right);
  @include fluid(300px, $breakpoint-xl, 20px, 48px, padding-bottom);
}

.nav {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
}

.item {
  max-width: 276px;
  margin-bottom: 2px;
}

.link {
  display: inline;
  padding-bottom: 5px;
  position: relative;
  font-family: 'GothamPro';
  font-weight: 700;
  font-size: 34px;
  line-height: 38px;
  letter-spacing: 2px;
  color: $black;
  text-transform: uppercase;
  text-decoration: none;

  &::after {
    @include position(absolute, null null 0 0);
    width: 100%;
    border-bottom: 2px solid $black;
    content: '';
  }
}

.active {
  color: $danger;

  &::after {
    border-color: $danger;
  }
}

.close {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 70px;
  height: 100%;
  border: none;
  background: none;
  border-left: 1px solid $black;
  color: $black;
}

.iconClose {
  width: 25px;
  height: 25px;
  stroke: currentColor;
}

.stripe {
  max-width: 376px;
  height: 36px;
}

.stripe1 {
  visibility: hidden;
  @include fluid(300px, $breakpoint-xl, 14px, 48px, padding-left);

  @include media-breakpoint-up(m) {
    visibility: visible;
  }
}

.stripe2 {
  display: block;
  margin-left: -44px;
  margin-bottom: 30px;
  max-width: 300px;
  height: 50px;

  @include media-breakpoint-up(m) {
    display: none;
  }
}

.bg {
  @include position(absolute, 70.45% -90px -94.04% -13.84%);
  background-image: url('../../assets/images/bg.png');
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
}
