@import '../../styles/vars';
@import '../../styles/mixins';

.content {
  height: 100%;

  @include fluid($breakpoint-xl, $breakpoint-3xl, 32px, 62px, padding-top);
  @include fluid($breakpoint-xl, $breakpoint-3xl, 54px, 84px, padding-left);
  @include fluid($breakpoint-xl, $breakpoint-3xl, 54px, 84px, padding-right);
  @include fluid($breakpoint-xl, $breakpoint-3xl, 32px, 62px, padding-bottom);

  @media screen and (max-width: 480px) {
    padding: 20px;
  }
}

.header {
  position: relative;
}

.title {
  margin-top: 0;
  @include fluid($breakpoint-xl, $breakpoint-3xl, 10px, 20px, margin-bottom);
  font-family: 'GothamPro';
  font-weight: 700;
  @include fluid($breakpoint-xl, $breakpoint-3xl, 45px, 75px, font-size);
  @include fluid($breakpoint-xl, $breakpoint-3xl, 50px, 80px, line-height);
  color: $white;

  @media screen and (max-width: 480px) {
    font-size: 32px;
    padding-left: 0;
    line-height: 1.2;
  }

}

.hint {
  max-width: 387px;
  margin-bottom: 36px;
  font-family: 'HPSimplified';
  font-weight: 400;
  @include fluid($breakpoint-xl, $breakpoint-3xl, 14px, 16px, font-size);
  line-height: 27px;
  color: $white;
}

.stripe {
  position: absolute;
  top: 0;
  @include fluid($breakpoint-xl, $breakpoint-3xl, -220px, -230px, left);
  width: 210px;
  @include fluid($breakpoint-xl, $breakpoint-3xl, 46px, 72px, height);
  background: repeating-linear-gradient(
    45deg,
    $danger,
    transparent 1px,
    transparent 8px
  );
}

.fg {
  @include position(absolute, 85% 3% null null);

  width: 210px;
  height: 210px;
  background-image: url('../../assets/images/svg/cross.svg');
  background-repeat: no-repeat;
  background-size: contain;
}
