@import '../../../styles/vars';
@import '../../../styles/mixins';

.section {
  position: relative;
  height: 100vh;
  padding-top: $headerHeight;
  background-color: $danger;
  overflow: hidden;
  height: auto;
  min-height: 100vh;
  padding-bottom: 100px;


  @media screen and (max-width: 480px) {
    height: auto;
    padding-bottom: 100px;
    padding-top: 70px;
  }
}

.container {
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
}

.content {
  padding: percent(16, $breakpoint-xl) percent(20, $breakpoint-xl) 0;

  @media screen and (min-height: 700px) {
    padding: percent(36, $breakpoint-xl) percent(20, $breakpoint-xl) 0;
  }

  @media screen and (min-height: 750px) {
    padding-top: percent(56, $breakpoint-xl);
  }

  @media screen and (max-width: 480px) {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}

.title {
  margin: 0;
  font-family: 'GothamPro';
  font-weight: 700;
  word-break: break-all;
  margin-bottom: .15em;
  text-transform: uppercase;
  color: $white;

  @include fluid(0px, $breakpoint-xl, 60px, 96px, font-size);
  @include fluid(0px, $breakpoint-xl, 55px, 100px, line-height);
  @include fluid(0px, $breakpoint-m, 254px, 280px, max-width);

  @include media-breakpoint-up(m) {
    max-width: initial;
    word-break: normal;
    @include fluid($breakpoint-m, $breakpoint-xl, 101px, 129px, line-height);
  }

  @include media-breakpoint-up(xl) {
    @include fluid($breakpoint-xl, $breakpoint-4xl, 112px, 240px, font-size);
    @include fluid($breakpoint-xl, $breakpoint-4xl, 130px, 213px, line-height);
  }

  @media screen and (max-width: 420px) {
    z-index: 3;
    position: relative;
  }
}

.subtitle {
  position: relative;
  z-index: 1;
  font-family: 'GothamPro';
  font-weight: 700;
  font-size: 18px;
  text-transform: uppercase;
  color: $white;
}

.description {
  position: relative;
  max-width: 398px;
  margin: percent(4, $breakpoint-xl) 0 percent(26, $breakpoint-xl) 0;
  font-size: em(14);
  line-height: em(27, 14);
  font-family: 'HPSimplified';
  color: $white;
  min-height: em(27, 14)*4;

  @include media-breakpoint-up(xl) {
    font-size: em(16);
    line-height: em(27, 16);
  }

  @media screen and (min-height: 700px) {
    margin: percent(4, $breakpoint-xl) 0 percent(46, $breakpoint-xl) 0;
  }

  @media screen and (max-width: 480px) {
    min-height: em(27, 14)*5;
  }
}

.bg {
  position: absolute;
  top: 30.45%;
  left: 36.55%;
  right: -11.56%;
  bottom: -8.8%;
  background-image: url('../../../assets/images/guy.png');
  background-size: contain;
  background-repeat: no-repeat;

  @media screen and (max-width: 480px) {
    opacity: .25 !important
  }
}

.bg.home {
  top: 30.45%;
  left: 36.55%;
  right: -11.56%;
  bottom: -8.8%;
  background-image: url('../../../assets/images/guy.png');
  @include media-breakpoint-up(m) {
    left: 63.55%;
  }

  @include media-breakpoint-up(xl) {
    top: 12.45%;
    left: 66.55%;
    right: -1.56%;
    bottom: -8.8%;
  }

  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (-webkit-min-device-pixel-ratio: 2) {
    top: 50.45%;
  }
}
.titleSizer {
  display: inline-block;
}
.label {
  font-size: 43px;
  line-height: 34px;

  span {
    display: inline-block;
    border-bottom: 2px solid #fff;
    padding-bottom :3px;
  }

  @media screen and (max-width: 680px ) {
    font-size: 24px;
    line-height: 28px;
    text-align: left !important;
  }

  @media screen and (max-width: 480px) {
    margin-bottom: 10px;
  }
}

.cs .label {
  text-align: right;
}

.bg.cs {
  left: 59.79%;
  right: 2.5%;
  top: 30.41%;
  bottom: 0%;
  background-image: url('../../../assets/images/soldier-sm-red.png');
  background-position: center bottom;

  @media screen and (max-width: 960px ) {
    width: 600px;
    left: 20%;
    right: auto;
    background-position: right bottom;
  }

  @media screen and (max-width: 500px ) {
    width: 600px;
    left: -20%;
    right: auto;
    background-position: right bottom;
  }

  @media screen and (max-width: 480px) {
    left: -40%;
  }
}

.bg.dota {
  left: 58.79%;
  right: 10.5%;
  top: 15.41%;
  bottom: 0%;
  background-image: url('../../../assets/images/girl_omen.png');
  background-position: center bottom;

  @media screen and (max-width: 960px ) {
    width: 300px;
    left: auto;
    right: -20px;
    background-position: right bottom;
  }

}

.fg {
  display: none;
  position: absolute;
  left: 64.37%;
  top: 51.59%;
  right: -23.39%;
  bottom: 8.89%;
  background-image: url('../../../assets/images/svg/cross.svg');
  background-repeat: no-repeat;
  background-size: contain;

  @include media-breakpoint-up(m) {
    display: block;
  }

  @include media-breakpoint-up(xl) {
    top: 41.59%;
    right: -13.39%;
  }

  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (-webkit-min-device-pixel-ratio: 2) {
    top: 71.59%;
  }
}

.box {
  position: relative;
  max-width: 338px;
  margin: percent(8, $breakpoint-xl) 0 percent(8, $breakpoint-xl);
  border: 1px solid #fff;
  flex-wrap: wrap;

  @media screen and (max-width: 480px) {
    margin-left: -10px !important;
    margin-right: -10px !important;
  }
}

.boxRow {
  display: flex;
  width: 100%;
}

.boxColumn {
  flex-grow: 1;
}

.boxColumn + .boxColumn {
  border-left: 1px solid #fff;
}

.boxWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;

  button {
    border-right: 0;
  }
}

.boxSquare {
  box-sizing: border-box;
  flex-shrink: 0;
  position: relative;
  height: 100%;
}

.boxPattern {
  position: absolute;
  top: 10px;
  left: 10px;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
}

.boxTitle {
  color: #fff;
  padding: 10px 15px;
  border-bottom: 1px solid #fff;
  font-size: 12px;
  line-height: 16px;
  flex-grow: 1;
}