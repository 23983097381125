/**
 * Use a better box model (opinionated).
 */

html {
	box-sizing: border-box;
}

*,
*::before,
*::after {
	box-sizing: inherit;
}

/**
 * 1. Correct the line height in all browsers.
 * 2. Prevent adjustments of font size after orientation changes in iOS.
 */

html {
	line-height: 1.15; /* 1 */
	-webkit-text-size-adjust: 100%; /* 2 */
}

body {
	margin: 0;
}

hr {
	height: 0;
}

b,
strong {
	font-weight: bolder;
}

small {
	font-size: 80%;
}
