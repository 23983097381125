@font-face {
  font-family: 'HPSimplified';
  src: url('./assets/fonts/HPSimplified-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'HPSimplified';
  src: url('./assets/fonts/HPSimplified-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'GothamPro';
  src: url('./assets/fonts/GothamPro-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'GothamPro';
  src: url('./assets/fonts/GothamPro-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}
