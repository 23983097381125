@import '../../../styles/vars';
@import '../../../styles/mixins';

.cover {
  @include aspect-ratio(40, 21);
  clip-path: polygon(100% 0, 100% calc(100% - 16px), calc(100% - 16px) 100%, 0 100%, 0 0);
  background-color: #fff;
}

.image {
  @include position(absolute, 0 1px 1px 1px);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: $danger;
  background-blend-mode: multiply;
  clip-path: polygon(100% 0, 100% calc(100% - 16px), calc(100% - 16px) 100%, 0 100%, 0 0);
}

.data {
  background: #fff;
  position: relative;
  clip-path: polygon(16px 0, 100% 0%, 100% 100%, 0 100%, 0 16px);
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;

  &::before {
    content: "";
    display: block;
    background-color: $danger;
    @include position(absolute, 1px 1px 1px 1px);
    clip-path: polygon(16px 0, 100% 0%, 100% 100%, 0 100%, 0 16px);
    z-index: -1;
  }
}

.title {
  font-size: 16px;
  line-height: em(20px, 16px);
  font-family: 'GothamPro';
  font-weight: bold;
  color: #fff;
  margin-bottom: 5px;
}

.meta {
  padding-left: 20px;
  flex-grow: 1;
  padding: 10px 5px 10px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.box {
  border-left: 1px solid #fff;
  flex: 0 0 80px;
  min-height: 80px;
  width: 80px;
  max-width: 80px;
  position: relative;
}

.label {
  font-family: 'GothamPro';
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 10px;
  letter-spacing: 1.57143px;
  text-transform: uppercase;
  margin: 10px 0 26px 0;
  text-align:center;
}

.amount {
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 34px;
  text-transform: uppercase;
  color: #000000;
  text-align: center;
}

.stripe {
  border:10px solid transparent;
}
.hoverable {
  cursor: pointer;
  transition: transform .3s;
}
.hoverable:hover {
  transform: translateY(-10px);
}

.arrow {
  position:absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)
}

.descr {
  font-weight: normal;
  font-size: 10px;
  line-height: 13px;
}