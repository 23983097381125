@import '../../styles/vars';
@import '../../styles/mixins';

:global {
  .ReactModal__Overlay {
    z-index: 4000;
    background-color: $black !important;
  }

  .ReactModal__Content {
    width: 100% !important;
    max-width: 234px !important;
    border-radius: 0 !important;
    border: none !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    background: none !important;
    padding: 0 !important;
  }
}

.container {
  position: relative;
  z-index: 1;
}

.body {
  height: 180px;
  padding: 16px;
  background-color: $danger;
}

.title {
  margin-bottom: 45px;
  font-family: 'GothamPro';
  font-weight: 700;
  letter-spacing: 0.9px;
  font-size: 27px;
  line-height: 33px;
  text-transform: uppercase;
  color: $white;
}

.description {
  font-family: 'HPSimplified';
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: $white;
}

.footer {
  position: relative;
  z-index: 1;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  clip-path: polygon(100% 0, 100% 84%, 96% 100%, 0 100%, 0 0);
  background-color: $white;
}

.button {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 16px;
  font-size: 16px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 1.8px;
  color: $black;
  text-transform: uppercase;
  outline: none;
  background: none;
  border: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.stripe {
  @include position(absolute, null null -15px 0);
  width: 100%;
  height: 30px;
}

