@import '../../../styles/vars';
@import '../../../styles/mixins';

.section {
  position: relative;
  background-color: $danger;
  padding-bottom: em(80);
  overflow: hidden;

  &::before {
    content: '';
    background: $white;
    position: absolute;
    top: 0;
    left: 0;
    height: 46px;
    width: 100%;
  }
}

.slides {
  position: relative;
  z-index: 1;
  padding-top: em(80);
  width: calc(100vw * 2);
  transition: transform .3s ease-in-out;
}

.slide {
  width: 100vw;
  vertical-align: top;
  display: inline-block;
  &_team {
    overflow: hidden;
  }
}

.content {
  z-index: 1;
  height: auto;
  padding-bottom: 1px;
  // @include fluid($breakpoint-xl, $breakpoint-4xl, 820px, 900px, height);
  @include fluid($breakpoint-xl, $breakpoint-4xl, 30px, 70px, padding-top);
  @include fluid($breakpoint-xl, $breakpoint-4xl, 36px, 386px, padding-left);
  //max-height: 650px;
}

.title {
  max-width: 300px;
  margin: 0 0 25px;
  font-family: 'GothamPro';
  font-size: 33px;
  line-height: 40px;
  letter-spacing: 3.5px;
  font-weight: bold;
  text-transform: uppercase;
  color: $white;

  @include media-breakpoint-up(l) {
    max-width: 350px;
  }

  @include media-breakpoint-up(xl) {
    max-width: 505px;
    font-size: 46px;
    letter-spacing: 5px;
    line-height: 62px;
  }
}

.description {
  max-width: 310px;
  font-family: 'HPSimplified';
  font-size: 16px;
  font-weight: 400;
  line-height: 27px;
  color: $white;
  margin: 0 0 28px;

  @include media-breakpoint-up(l) {
    max-width: 430px;
  }
}

.bg {
  z-index: -1;
  position: absolute;

  @include fluid($breakpoint-xl, $breakpoint-4xl, 436px, 496px, width);
  @include fluid($breakpoint-xl, $breakpoint-4xl, 614px, 726px, height);
  left: -142px;
  bottom: -92px;
  background-image: url('./guy-2.png');
  background-repeat: no-repeat;
  background-size: cover;
  transform: scaleX(-1);
}

.bg2 {
  position: absolute;
  z-index: -1;
  bottom: -114px;
  right: 0;
  background-image: url('./guy-3.png');
  background-repeat: no-repeat;
  background-size: cover;
  @include fluid($breakpoint-xl, $breakpoint-4xl, 778px, 838px, width);
  @include fluid($breakpoint-xl, $breakpoint-4xl, 646px, 706px, height);
}

.cross {
  position: absolute;
  z-index: -1;
  top: 85%;
  right: 90%;
  background-image: url('../../../assets/images/svg/cross.svg');
  background-repeat: no-repeat;
  background-size: contain;
  width: 550px;
  height: 550px;
}

.stripe {
  @include position(absolute, 0 -100px null null);

  width: 413px;
  height: 93px;
}

.gameCards {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  @include fluid($breakpoint-xl, $breakpoint-4xl, 800px, 977px, max-width);
  margin-top: 84px;
}

.gameCardItem {
  width: 30%;
}

.teamCards {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  max-width: 1078px;
  margin-top: 84px;
}

.teamCardItem {
  width: 22%;
}

.nextSlide {
  position: absolute;
  width: 100%;
  cursor: pointer;
  right: -228px;
  max-width: 420px;
  @include fluid($breakpoint-xl, $breakpoint-4xl, 30px, 70px, padding-top);
  z-index: 3;
  transition: transform .6s cubic-bezier(.94,-0.18,0,1.36);
}

.prevSlide {
  max-width: 470px;

  @include media-breakpoint-up(l) {
    left: -360px;

    .arrow {
      transform: scaleX(-1);
    }
  }
}

.nextSlideButton {
  display: none;
  cursor: pointer;
  padding: 20px 0;

  @include media-breakpoint-up(l) {
    display: inline-block;
    position: absolute;
    top: 0;
    left: -110px;
  }
}

.arrow {
  position: absolute;
  top: 65px;
  width: 74px;
  height: 24px;
  margin-left: -100px;
  transition: transform .3s ease-in-out;
}

.slideTitle {
  margin: 0 0 20px -7px;
  font-family: 'GothamPro';
  font-size: em(33);
  font-weight: 700;
  letter-spacing: 3.5px;
  line-height: em(40, 33);
  text-transform: uppercase;
  color: $white;

  @include media-breakpoint-up(xl) {
    font-size: em(46);
    line-height: em(62, 46);
    letter-spacing: 5px;
  }
}

.slideDescription {
  max-width: 430px;
  font-family: 'HPSimplified';
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  color: $white;
}

.slide_team {
  .text {
    position: relative;
    height: 250px;
    @include fluid($breakpoint-xl, $breakpoint-4xl, 200px, 0px, margin-left);
    max-width: 1078px;
  }
}

.slide_game {
  .text {
    transition: transform .8s cubic-bezier(.94,-0.18,0,1.36);
  }
}

.buttonWrapper {
  @include position(absolute, 0 0 null null);
}

.button {
  @include position(absolute, 0 0 null null);
  display: flex;
  align-items: center;
  width: 210px;
  height: 48px;
  margin: 0;
  padding: 0;
  border: 1px solid $white;
  text-transform: uppercase;
  background-color: transparent;
  cursor: pointer;
  outline: 0;
}

.buttonText {
  padding: 0 10px 0 12px;
  font-family: 'GothamPro';
  font-weight: 700;
  font-size: 14px;
  line-height: 12px;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  color: $white;
  flex: 1 0 auto;
}

.buttonFingerPrint {
  display: flex;
  border-left: 1px solid $white;
  height: 46px;
  padding: 8px;
}

.leftStripe {
  width: 50%;
  transform: scaleY(-1);
}

.rightStripe {
  width: 50%;
  transform: scale(-1);
}

.bottomStripe {
  @include position(absolute, null null -18px 0);
  height: 18px;
}
