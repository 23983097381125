@import '../../styles/vars';

.select {

}

.fullWidth {
  width: 100%;

  .menu {
    width: 100%;
  }
}

.popup {
  position: relative;
  z-index: 20;
}

.wrapper {
  position: absolute;
  width: 100%;
  height: 208px;
  clip-path: polygon(100% 0, 100% 90%, 92% 100%, 0 100%, 0 0);
  background-color: $white;
}

.popupBorder {
  position: absolute;
  top: 0px;
  left: 1px;
  right: 1px;
  bottom: 1px;
  clip-path: polygon(100% 0, 100% 90%, 92% 100%, 0 100%, 0 0);
  background-color: $black;
}

.menu {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-height: 208px;
  overflow-y: scroll;
  padding: 6px 18px;
}

.menuItem {
  position: relative;
  padding: 15px 0;
  color: $white;
  cursor: pointer;

  &.active,
  &:hover {
    color: $danger;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 100%;
      height: 6px;
      background: repeating-linear-gradient(
        45deg,
        $danger,
        transparent 1px,
        transparent 7px
      );
    }
  }
}

.menuItemText {
  position: relative;
  z-index: 1;
  padding-right: 20px;
  background: $black;
}
