@import '../../styles/_vars';

$control-height: 50px;
$control-border-color: #6D7278;
$control-border-offset: 20px;

.root {
  input {
    border: 1px solid $control-border-color;
    background-color: transparent;
    outline: none;
    height: $control-height;
    font-family: 'GothamPro';
    font-size: 16px;
    color: #6D7278;
    display: inline-block;
    width: 100%;
    padding: 0 $control-border-offset;
    transition: border-color .3s;
    &:focus {
      border-color: darken($control-border-color, 20%)
    }
  }

  textarea {
    resize: vertical;
    display: inline-block;
    width: 100%;
    background-color: transparent;
    font-family: 'GothamPro';
    color: #6D7278;
    font-size: 16px;
    line-height: 1.32;
    padding: $control-border-offset*.75 $control-border-offset;
    outline: none;
    border: 1px solid $control-border-color;
    min-height: 90px;

    &:focus {
      border-color: darken($control-border-color, 20%)
    }
  }

  &.onError {
    input {
      border-color: $danger;
    }
  }
}

.error {
  color: $danger;
  font-size: 12px;
  margin-top: 6px;
}