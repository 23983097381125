.bar {
  position: relative;
  width: 46.8%;
}

.fill {
  position: absolute;
  top: 50%;
  left: 0;
  transform-origin: left;
  width: 100%;
  height: 3px;
}

.bg {
  height: 1px;
  opacity: 0.4;
}

:global(.isMobile) {
  .bg {
    background: none !important;
  }
}
