@import '../../styles/vars';
@import '../../styles/mixins';

.menu {
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 52px;
  border: 1px solid $white;

  @include media-breakpoint-up(m) {
    width: 410px;
  }
}

.wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding-left: em(12);
}

.item {
  display: flex;
  align-items: center;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &:hover {
    .label {
      font-weight: 700;
    }
  }
}

.label {
  display: inline-block;
  margin-left: 10px;
  font-size: 18px;
  font-family: 'GothamPro';
  font-weight: 700;
  line-height: 20px;
  color: $white;
}

.icon {
  display: inline-block;
  width: 24px;
  height: 24px;
  stroke: $white;
}

.button {
  display: inline-block;
  background: $white;
  cursor: pointer;
  overflow: hidden;
  flex-shrink: 0;
}

.buttonIcon {
  display: inline-block;
  width: 51px;
  height: 100%;
}
