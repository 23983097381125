@import '../../../styles/vars';
@import '../../../styles/mixins';

.card {
  @include position(absolute, 0);
  text-decoration: none;

  &:hover {
    .cover {
      background-color: $danger;
    }

    .image {
      transform: translate(0, -50%) scale(1);
    }
  }
}

.wrapper {
  @include position(absolute, 0 1px 0 1px);
}

.frame {
  position: absolute;
  z-index: 1;
  stroke: $white;
}

.cover {
  @include aspect-ratio(6, 7);
  overflow: hidden;
  transition: background-color 0.3s;
}

.image {
  @include position(absolute, 50% null null null);

  transform: translate(25%, -50%) scale(0.8);
  transition: transform 0.3s;
  max-width: 100%;
}

.description {
  font-family: 'HPSimplified';
  font-weight: 400;
  @include fluid($breakpoint-xl, $breakpoint-4xl, 14px, 16px, font-size);
  line-height: 19px;
  color: $white;
}

.title {
  font-family: 'GothamPro';
  font-weight: 700;
  @include fluid($breakpoint-xl, $breakpoint-4xl, 16px, 18px, font-size);
  @include fluid($breakpoint-xs, $breakpoint-xl, 13px, 15px, font-size);
  line-height: 18px;
  letter-spacing: 0.7px;
  text-transform: uppercase;
  color: $danger;
}

.body {
  @include aspect-ratio(11, 3);
}

.container {
  @include position(absolute, 0);

  display: flex;
  border-top: 1.3px solid $white;
}

.content {
  display: flex;
  flex-shrink: 0;
  width: 72%;
  flex-direction: column;
  justify-content: space-around;
  @include fluid($breakpoint-xl, $breakpoint-4xl, 7px, 21px, padding-left);
  @include fluid($breakpoint-xl, $breakpoint-4xl, 7px, 21px, padding-right);

  @include fluid($breakpoint-xs, $breakpoint-xl, 3px, 7px, padding-left);
  @include fluid($breakpoint-xs, $breakpoint-xl, 3px, 7px, padding-right);
}

.extra {
  position: relative;
  display: flex;
  align-items: flex-end;
  flex-shrink: 0;
  width: 28%;
  padding: 10px;
  cursor: pointer;
  font-family: 'GothamPro';
  font-weight: 700;
  border-left: 1px solid $white;
  color: $white;

  @include fluid($breakpoint-xs, $breakpoint-xl, 3px, 5px, padding-left);
  @include fluid($breakpoint-xs, $breakpoint-xl, 3px, 5px, padding-right);
}

.place {
  font-size: 41px;
  line-height: 28px;

}

.placeLabel {
  font-size: 10px;
  margin-left: 2px;

  @include fluid($breakpoint-m, $breakpoint-xl, 9px, 10px, font-size);
  @include fluid($breakpoint-m, $breakpoint-xl, 9px, 10px, line-height);
}
