@import '../../styles/vars';
@import '../../styles/mixins';

.header {
  position: fixed;
  top: 0;
  z-index: 2020;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: $headerHeight;
  border-top: 1px solid $white;
  border-bottom: 1px solid $white;
}

.isSticky {
  .fill {
    transform: scaleX(1);
  }
}

.fill {
  @include position(absolute, 0 null null 0);
  z-index: -1;
  width: 100%;
  height: $headerHeight - 2;
  background-color: $black;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.4s ease;
  will-change: transform;
}

.left,
.right {
  display: flex;
}

.logo {
  display: flex;
  align-items: center;
  flex-grow: 0;
  @include fluid(0px, $breakpoint-xl, 10px, 20px, padding-left);
  @include fluid(0px, $breakpoint-xl, 10px, 20px, padding-right);
  text-decoration: none;
  cursor: pointer;

  &:last-child {
    fill: #fff;
  }
}

.nav {
  display: flex;
}

.icon {
  display: inline-block;
}

.iconOmen {
  width: 102px;
  height: 16px;
}

.iconMicrosoft {
  width: 88px;
  height: 19px;
}

.button {
  position: relative;
  display: flex;
  height: 100%;
  margin: 0;
  padding: 10px;
  border: 0;
  border-left: 1px solid $white;
  outline: 0;
  overflow: hidden;
  background-color: transparent;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.buttonInner {
  @include position(absolute, 50% null null 50%);

  transform: translate(-50%, -50%);
}

.stripe {
  max-width: 48px;
}
