@import '../../styles/vars';

.input {
  display: inline-block;
}

.box {
  display: inline-block;
  position: relative;
  width: 100%;
}

.control {
  display: inline-block;
  width: 100%;
  height: 50px;
  padding: 0 18px;
  font-family: 'HPSimplified';
  font-size: 16px;
  line-height: 21px;
  background: none;
  color: $white;
  outline: none;
  appearance: none;
  border: 1px solid $white;

  &:focus {
    outline: none;
  }
}

.label {
  display: inline-block;
  margin-bottom: 8px;
  font-family: 'GothamPro';
  font-weight: 700;
  font-size: 12px;
  line-height: 11px;
  color: $white;
}

.fullWidth {
  width: 100%;
}

.isReadonly {
  .control {
    cursor: pointer;
    user-select: none;
  }
}

.icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.iconRight {
  right: 22px;
}
