@import '../../../styles/vars';
@import '../../../styles/mixins';

.section {
  position: relative;
  background-color: $danger;
  padding-bottom: em(80);
  overflow: hidden;

  &::before {
    content: '';
    background: $white;
    position: absolute;
    top: 0;
    left: 0;
    height: 46px;
    width: 100%;
  }
}

.slides {
  position: relative;
  z-index: 1;
  padding-top: em(80);
}

.content {
  position: relative;
  z-index: 1;

  @include fluid($breakpoint-xl, $breakpoint-4xl, 30px, 70px, padding-top);
  @include fluid($breakpoint-xl, $breakpoint-4xl, 6px, 82px, padding-bottom);
  @include fluid($breakpoint-xl, $breakpoint-4xl, 36px, 386px, padding-left);

  @media screen and (max-width: 480px) {
    padding-left: 20px !important
  }
}

.title {
  max-width: 320px;
  margin: 0 0 25px;
  font-family: 'GothamPro';
  font-size: 33px;
  line-height: 40px;
  letter-spacing: 3.5px;
  font-weight: bold;
  text-transform: uppercase;
  color: $white;

  @include media-breakpoint-up(l) {
    max-width: 350px;
  }

  @include media-breakpoint-up(xl) {
    max-width: 505px;
    font-size: 46px;
    letter-spacing: 5px;
    line-height: 62px;
  }
}

.description {
  max-width: 310px;
  font-family: 'HPSimplified';
  font-size: 16px;
  font-weight: 400;
  line-height: 27px;
  color: $white;
  margin: 0 0 28px;

  @include media-breakpoint-up(l) {
    max-width: 430px;
  }
}

.bg {
  z-index: -1;
  position: absolute;

  @include fluid($breakpoint-xl, $breakpoint-4xl, 436px, 496px, width);
  @include fluid($breakpoint-xl, $breakpoint-4xl, 614px, 726px, height);
  left: -142px;
  bottom: -92px;
  background-image: url('../SectionGames/guy-2.png');
  background-repeat: no-repeat;
  background-size: cover;
  transform: scaleX(-1);
}

.cross {
  position: absolute;
  z-index: -1;
  top: 85%;
  right: 90%;
  background-image: url('../../../assets/images/svg/cross.svg');
  background-repeat: no-repeat;
  background-size: contain;
  width: 550px;
  height: 550px;
}

.stripe {
  @include position(absolute, 0 -100px null null);

  width: 413px;
  height: 93px;
}

.gameCards {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  @include fluid($breakpoint-xl, $breakpoint-4xl, 800px, 977px, max-width);
  margin-top: 84px;
}

.gameCardItem {
  width: 30%;
}

.teamCards {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  max-width: 1078px;
  margin-top: 84px;
}

.teamCardItem {
  width: 22%;
}

:global(.isMobile) {
  .content {
    overflow: hidden;
    max-width: none;
  }

  .gameCards {
    overflow-x: scroll;
    margin-bottom: -14px;
  }

  .gameCardItem {
    min-width: 240px;

    &:not(:first-child) {
      margin-left: 30px;
    }
  }

  .teamCards {
    overflow-x: scroll;
    margin-bottom: -14px;
    padding-bottom: 14px;
  }

  .teamCardItem {
    min-width: 218px;

    &:not(:first-child) {
      margin-left: 30px;
    }
  }
}

